import { blobToBinary } from "utils/miscUtils";

const { Client } = require("./_clientService");

async function createPack2(pack) {
  try {
    const response = await new Client({
      path: `/player/pack/create`,
      payload: pack,
    }).post();

    return response;
  } catch (error) {
    throw error;
  }
}

async function updatePack2(pack) {
  try {
    const response = await new Client({
      path: `/player/pack/update`,
      payload: pack,
    }).patch();
    return response;
  } catch (error) {
    console.error("Error creating pack:", error);
    throw error;
  }
}

async function getPackImages(pack) {
  try {
    const response = await new Client({
      path: `/player/pack/predefined-images/list`,
    }).get();
    return response;
  } catch (error) {
    throw error;
  }
}

async function updatePack(data) {
  let payload = {
    _id: data._id,
    name: data.name,
    type: data.type[0],
    summary: data.summary,
    price: parseFloat(data.price) || 0.0,
    slots: data.slots,
    isFTUPack: data.isFTUPack || false,
  };

  try {
    const response = await new Client({
      path: `/player/pack/update`,
      payload,
    }).patch();
    return response;
  } catch (error) {
    console.error("Error creating pack:", error);
    throw error;
  }
}

async function createPack(data) {
  let payload = {
    name: data.packName,
    type: data.packType[0],
    summary: data.packDescription,
    price: parseFloat(data.packPrice) || 0.0,
    slots: data.slots,
    isFTUPack: data.isFTUPack || false,
  };

  try {
    const response = await new Client({
      path: `/player/pack/create`,
      payload,
    }).post();
    return response;
  } catch (error) {
    console.error("Error creating pack:", error);
    throw error;
  }
}

async function getPlayerPacks(options) {
  let payload = {
    pageNo: options?.pageNo ?? 1,
    limit: options.pageSize ?? 10,
    sortKey: options.sortBy ?? "createdAt",
    sortDir: options?.sortDir ?? "DESC",
    search: options?.search ?? "",
  };

  let response = await new Client({
    path: "/player/pack/list",
    payload,
  }).post();

  const { items } = response?.data;

  const updatedList = items?.map((el) => {
    return {
      ...el,
      isDisabled: el.isDisabled ? "true" : "false",
      summary:
        el?.summary?.length > 25
          ? `${el.summary.substring(0, 25)}...`
          : el.summary,
    };
  });

  let returnData = {
    message: response.message,
    ...response.data,
    items: updatedList,
  };

  return returnData;
}

async function packInfo(packId) {
  try {
    let path = "/player/pack/info";
    const response = await new Client({ path, payload: { packId } }).post();
    return response;
  } catch (error) {
    throw error;
  }
}
async function getBoosters() {
  const path = `/boosters/list`;
  const response = await new Client({ path }).get();
  return response;
}

async function getCountries() {
  const path = `/countries/list`;
  const response = await new Client({ path }).get();
  return response;
}

export {
  createPack2,
  updatePack2,
  getPackImages,
  createPack,
  getPlayerPacks,
  getBoosters,
  getCountries,
  updatePack,
  packInfo,
};
