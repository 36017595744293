/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Table from "components/Table";
import ContentShell from "components/ContentShell";
import { Button, Box, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import configData from "config/config.json";
import { getPlayerCardUpdates } from "services/playerCardService";
import { useSnackbar } from "hooks/useSnackbar";
import usePaginationHook from "hooks/usePagination";
import { generateOptions, opacity2hex, sortArray } from "utils/miscUtils";
import SearchInput from "components/SearchInput";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import sessionHelper from "services/sessionHelper";
import { PermittedRoles } from "utils/permissions";
import { SimpleSelectInput } from "components/FormSelectInput";
import { getTFGPlayerTableColumns } from "utils/tableHelper/playersTableHelper";
import useSearchHook from "hooks/useSearch";
import { updateQueryParams } from "utils/queryParamsHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "fit-content",
    flexFlow: "row wrap",
    gap: 4,
  },
  field: {
    width: 180,
  },
  buttonContainer: {
    minWidth: 90,
    maxWidth: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 24,
  },
  button: {
    height: 30,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textTransform: "none",
    width: "100% !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      maxWidth: "100% !important",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  editButton: {
    backgroundColor: theme.palette.common.offWhite + opacity2hex("0.80"),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  playerCardParent: {
    padding: "12px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const RowActions = ({ data, actionHandlers: { onRead, onReadAndCreate } }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => onReadAndCreate(data)}>
          Read & Create new Card
        </MenuItem>
        <MenuItem onClick={() => onRead(data)}>Read</MenuItem>
      </Menu>
    </>
  );
};

const FilterSection = ({
  handleChange = () => {},
  onSubmitClick = () => {},
  filterOptions,
}) => {
  const classes = useStyles();
  const clubOptions = generateOptions({
    items: sessionHelper.clubs,
    id: "abbreviation",
    label: "name",
    addAllOption: true,
  });

  return (
    <Box className={classes.root}>
      <Box className={classes.field}>
        <SimpleSelectInput
          name="club"
          label="Club"
          options={clubOptions}
          value={filterOptions.club}
          onChange={handleChange}
          customStyle={{ height: 30 }}
        />
      </Box>
      <Box className={classes.field}>
        <SimpleSelectInput
          name="nationality"
          label="Nationality"
          options={sessionHelper.countryOptions}
          value={filterOptions.nationality}
          onChange={handleChange}
          customStyle={{ height: 30 }}
        />
      </Box>
      <Box className={classes.buttonContainer}>
        <Button className={classes.button} onClick={onSubmitClick}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

const TFGPlayerUpdate = () => {
  const styles = useStyles();
  const initialFilters = {
    club: "all",
    nationality: "all",
  };
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const [orderDirection, setOrderDirection] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const [modalInfo, setModalInfo] = React.useState({
    modalState: false,
    label: "Full Player Card",
    imageUrl: "",
    cardDetails: null,
  });

  const { searchValue, onSearch: _onSearch } = useSearchHook();
  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.PLAYER.CARD.UPDATE_ROLES
  );
  const [columns] = useState(
    getTFGPlayerTableColumns().filter(
      (col) => col.id !== "actions" || hasEditPermission
    )
  );

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({
      totalItems: filteredList?.length,
    });

  const onColumnClick = (column) => {
    let _list = sortArray([...filteredList], orderDirection, column);
    setFilteredList(_list);
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const openModal = (cardDetails) => {
    console.log("cardDetails: ", cardDetails);

    setTimeout(
      () =>
        setModalInfo((st) => ({
          ...st,
          modalState: true,
          cardDetails: {
            ...cardDetails,
            ...cardDetails?.extraData,
            position: cardDetails?.preferredPosition,
            cardType: cardDetails?.rarity,
            defenceRating: cardDetails?.defenseRating,
          },
        })),
      0
    );
  };

  const closeModal = () => setModalInfo((st) => ({ ...st, modalState: false }));

  const handleCreatePlayerCard = () =>
    navigate(configData.routes.playerCard.createPlayerCard);

  const onRead = async () => {
    try {
      // const response = await read
    } catch (error) {
      setLoading(false);
      showSnackbar(error?.message ?? error, "error");
    }
  };

  const onReadAndCreate = (data) => {
    navigate(
      `${configData.routes.playerCard.createPlayerCard}?playerId=${data.playerId}`
    );
    // updateQueryParams({ playerId: data?.playerId }, setSearchParams);
  };

  const fetchTFGPlayersUpdates = async () => {
    setLoading(true);
    try {
      let payload = {
        pageNo,
        pageSize,
      };
      if (searchValue) payload["search"] = searchValue;
      const response = await getPlayerCardUpdates(payload);
      showSnackbar(response?.message, "success");
      setFilteredList(
        response?.items?.map((el) => ({ ...el, thumbnail: el?.miniImageUrl }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error?.message ?? error, "error");
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e?.target;
    setFilterOptions((_state) => ({ ..._state, [name]: value }));
  };

  const onSearch = (value) => {
    // reset filters if any

    _onSearch(value);
  };

  useEffect(() => {
    fetchTFGPlayersUpdates();
  }, [pageNo, pageSize, searchValue]);

  return (
    <ContentShell
      title="TFG Player Updates"
      loading={loading}
      actions={
        <Box style={{ display: "flex", flexFlow: "row nowrap", gap: 4 }}>
          <SearchInput
            value={searchValue}
            placeholder="Search a player card"
            onSearch={onSearch}
          />
          {/* <ExportButton list={filteredList} fileName="Player-cards" />
          {sessionHelper.hasRole(PermittedRoles.PLAYER.CARD.CREATE_ROLES) && (
            <CustomButton onClick={handleCreatePlayerCard}>Create</CustomButton>
          )} */}
        </Box>
      }
    >
      <Box className={styles.playerCardParent}>
        {/* <FilterSection
          filterOptions={filterOptions}
          handleChange={handleFilterChange}
          onSubmitClick={fetchTFGPlayersUpdates}
        /> */}
        <Table
          columns={columns}
          data={filteredList}
          currentPage={pageNo}
          pageSize={pageSize}
          totalEntries={filteredList.length}
          highlightAltRow={true}
          actions={RowActions}
          onColumnClick={onColumnClick}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
          actionHandlers={{
            onRead,
            onReadAndCreate,
            openModal: openModal,
          }}
        />
        {/* <ViewFullCardModal
          label={modalInfo.label}
          isOpen={modalInfo.modalState}
          close={closeModal}
          cardDetails={modalInfo.cardDetails}
        /> */}
      </Box>
    </ContentShell>
  );
};

export default TFGPlayerUpdate;
