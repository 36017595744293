import { Client } from "./_clientService";

async function getBanners(options) {
  try {
    let payload = {
      pageNo: options?.pageNo,
      limit: options?.pageSize,
      sortKey: "createdAt",
      sortDir: "ASC",
      search: options?.searchValue,
      skipPagination: options?.skipPagination ?? false,
    };
    let path = "/banner/list";
    const resp = await new Client({ path, payload }).post();

    return resp;
  } catch (error) {
    throw error;
  }
}

function getBannerInfo(bannerId) {
  try {
    const resp = new Client({
      path: "/banner/info",
      payload: {
        bannerId: bannerId,
      },
    }).post();
    return resp;
  } catch (error) {
    throw error;
  }
}

function createBanner(bannerDetails) {
  try {
    const resp = new Client({
      path: "/banner/create",
      payload: bannerDetails,
    }).post();
    return resp;
  } catch (error) {
    throw error;
  }
}

function updateBanner(bannerDetails, bannerId) {
  try {
    const resp = new Client({
      path: "/banner/update",
      payload: { ...bannerDetails, _id: bannerId },
    }).post();
    return resp;
  } catch (error) {
    throw error;
  }
}

export { getBanners, getBannerInfo, createBanner, updateBanner };
