import React, { useState, useCallback, useEffect } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { InputShell } from "./FormInputShell";
import debounce from "lodash.debounce";

const SearchableInput = ({
  name,
  selected,
  options,
  label,
  placeholder,
  onInputChange,
  onOptionSelect,
  customStyle,
  loading,
  fetchMore,
  hasMore,
  isMultiSelect = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(selected?.label ?? "");
  const [selectedOption, setSelectedOption] = useState(selected ?? null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const debouncedInputChange = useCallback(
    debounce((newInputValue) => {
      if (onInputChange) {
        onInputChange(newInputValue);
      }
    }, 300),
    [onInputChange]
  );

  const handleInputChange = (_, newInputValue, reason) => {
    if (reason === "input") {
      setInputValue(newInputValue);
      debouncedInputChange(newInputValue);
    }
  };

  const handleOptionChange = (e, newValue, reason) => {
    setSelectedOption(newValue);

    if (reason !== "clear") {
      setInputValue(
        !isMultiSelect && newValue && typeof newValue === "object"
          ? newValue.label || ""
          : ""
      );
    } else {
      setInputValue("");
    }

    if (onOptionSelect) {
      onOptionSelect({ target: { name, value: newValue } });
    }
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    const bottomReached =
      Math.round(listboxNode.scrollHeight - listboxNode.scrollTop) ===
      listboxNode.clientHeight;

    if (bottomReached && hasMore && !isFetchingMore) {
      fetchMore().finally(() => {
        setIsFetchingMore(false);
      });
    }
  };

  const isOptionEqualToValue = (option, value) => {
    return option.label === value.label;
  };

  return (
    <Autocomplete
      multiple={isMultiSelect}
      size={props.size ?? "small"}
      value={selectedOption}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleOptionChange}
      options={options}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={(options, state) =>
        options.filter((option) =>
          option.label?.toLowerCase().includes(state.inputValue?.toLowerCase())
        )
      }
      noOptionsText="No data Found"
      ListboxProps={{ onScroll: handleScroll }}
      loading={loading || isFetchingMore}
      renderInput={(params) => (
        <InputShell label={label} name={name}>
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            style={customStyle}
            InputLabelProps={{ style: { fontSize: 14 } }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading || isFetchingMore ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </InputShell>
      )}
      {...props}
    />
  );
};

export default React.memo(SearchableInput);
