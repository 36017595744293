/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "components/Table";
import ContentShell from "components/ContentShell";
import { Button, Box } from "@mui/material";
import SearchInput from "components/SearchInput";
import usePaginationHook from "hooks/usePagination";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "hooks/useSnackbar";
import configData from "config/config.json";
import { brandTableColumns } from "utils/tableHelper/pobTableHelper";
import sessionHelper from "services/sessionHelper";
import { PermittedRoles } from "utils/permissions";
import { getBrands } from "services/brandServices";
import CustomButton from "components/CustomButton";

const useStyles = makeStyles((theme) => ({}));

const defaultPageSize = 10,
  defaultPageNo = 1;

const RowActions = ({ rowId, data }) => {
  const navigate = useNavigate();
  const onEdit = (data) =>
    navigate(configData.routes.brands.edit?.replace(":id", data?._id));
  return <Button onClick={() => onEdit(data)}>Edit</Button>;
};

const Brands = () => {
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.BRAND.UPDATE_ROLES
  );

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({ defaultPageSize, defaultPageNo });

  const fetchData = async (v) => {
    setLoading(true);
    try {
      const resp = await getBrands({
        pageNo,
        pageSize: pageSize,
        search: v,
      });
      showSnackbar(resp.message, "success");
      setFilteredList(
        resp?.data?.brands?.map((item) => ({
          ...item,
          isActive: item?.isActive ? "true" : "false",
        }))
      );
      setLoading(false);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setSearchValue(value);
    onPageSizeChange({ target: { value: defaultPageSize } });
    fetchData(value);
  };

  const createPlatform = () => navigate(configData.routes.brands.create);

  useEffect(() => {
    const initialColumns = brandTableColumns().filter(
      (col) => col.id !== "actions" || hasEditPermission
    );
    setColumns(initialColumns);

    fetchData(searchValue);
  }, [pageNo, pageSize]);

  return (
    <ContentShell
      title="Brands"
      loading={loading}
      actions={
        <Box style={{ display: "flex", flexFlow: "row nowrap", gap: 4 }}>
          <SearchInput
            value={searchValue}
            placeholder="Search a brand"
            onSearch={onSearch}
          />
          {sessionHelper.hasRole(PermittedRoles.BRAND.CREATE_ROLES) && (
            <CustomButton onClick={createPlatform}>Create</CustomButton>
          )}
        </Box>
      }
    >
      <Box className={styles.playerCardParent}>
        <Table
          columns={columns}
          data={filteredList}
          currentPage={pageNo}
          actions={RowActions}
          pageSize={pageSize}
          totalEntries={filteredList?.length}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
          highlightAltRow={true}
        />
      </Box>
    </ContentShell>
  );
};

export default Brands;
