import { blobToBinary, setNumberFormat } from "utils/miscUtils";

const { Client } = require("./_clientService");

const CARD_TYPE_OPTIONS = [
  {
    id: "bronze",
    label: "Bronze",
  },
  {
    id: "silver",
    label: "Silver",
  },
  {
    id: "gold",
    label: "Gold",
  },
  {
    id: "inForm",
    label: "In Form",
  },
];

const JERSY_COLOR_TYPE_OPTIONS = [
  { id: "U", label: "U" },
  { id: "V", label: "V" },
];

const COUNTRY_OPTIONS = [
  {
    id: "Portugal",
    label: "Portugal",
  },
  {
    id: "Republic of Ireland",
    label: "Republic of Ireland",
  },
  {
    id: "Italy",
    label: "Italy",
  },
  {
    id: "England",
    label: "England",
  },
];

const BoosterSlotOptions = [
  {
    id: "Boost_All_05_5",
    label: "Boost_All_05_5",
  },
  {
    id: "Boost_Cre_05_3",
    label: "Boost_Cre_05_3",
  },
  {
    id: "Boost_Def_05_3",
    label: "Boost_Def_05_3",
  },
  {
    id: "Boost_Atk_05_3",
    label: "Boost_Atk_05_3",
  },
];

const jerseySizeOptions = [
  { label: "S", id: "S" },
  { label: "M", id: "M" },
  { label: "L", id: "L" },
  { label: "XL", id: "XL" },
];

async function uploadPlayerCardImage(params) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const file = await blobToBinary(params.data);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    const response = fetch(params?.url, requestOptions);

    return response;
  } catch (error) {
    throw error;
  }
}

async function createPlayerCard(cardInfo) {
  try {
    let data = {
      template: cardInfo?.template,
      isDisabled: false,
      displayName: cardInfo?.displayName,
      displayNameAbb: cardInfo?.displayNameAbb,
      club: cardInfo?.clubName,
      nationality: cardInfo?.nationality,
      preferredPosition: cardInfo?.position,
      attackRating: +cardInfo?.attackRating,
      defenseRating: +cardInfo?.defenceRating,
      creativityRating: +cardInfo?.creativityRating,
      rarity: cardInfo.cardType,
      tags: cardInfo.playerTags,
      clubShortCode: cardInfo.clubShortCode,
      commonNameForename: cardInfo.commonNameForename,
      commonNameSurname: cardInfo.commonNameSurname,
      extraData: {
        clubTextColor: cardInfo.clubTextColor,
        clubBGColor: cardInfo.clubBGColor,
        clubAbbrName: cardInfo.clubAbbrName,
        textColor: cardInfo.textColor,
        positionBGColor: cardInfo.positionBGColor,
      },
    };

    let path = "/player/card/create";
    const response = new Client({ path, payload: data }).post();

    return response;
  } catch (error) {
    throw error;
  }
}

async function updatePlayerCard(cardInfo) {
  try {
    let data = {
      _id: cardInfo?.cardId,
      isDisabled: false,
      displayName: cardInfo?.displayName,
      displayNameAbb: cardInfo?.displayNameAbb,
      club: cardInfo?.clubName,
      clubShortCode: cardInfo?.clubShortCode,
      nationality: cardInfo?.nationality,
      preferredPosition: cardInfo?.position,
      attackRating: +cardInfo?.attackRating,
      defenseRating: +cardInfo?.defenceRating,
      creativityRating: +cardInfo?.creativityRating,
      rarity: cardInfo.cardType,
      tags: cardInfo.playerTags,
      commonNameForename: cardInfo.commonNameForename,
      commonNameSurname: cardInfo.commonNameSurname,
      extraData: {
        clubTextColor: cardInfo.clubTextColor,
        clubBGColor: cardInfo.clubBGColor,
        clubAbbrName: cardInfo.clubAbbrName,
        textColor: cardInfo.textColor,
        positionBGColor: cardInfo.positionBGColor,
      },
    };
    let path = "/player/card/update";
    const response = new Client({ path, payload: data }).patch();
    return response;
  } catch (error) {
    throw error;
  }
}

async function playerCardInfo(cardId) {
  try {
    let path = "/player/card/info";
    const response = await new Client({ path, payload: { cardId } }).post();

    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getPlayerCardTemplate(options) {
  let payload = {
    pageNo: options?.pageNo ?? 1,
    limit: options.pageSize ?? 10,
    sortKey: options.sortBy ?? "createdAt",
    sortDir: options?.sortDir ?? "DESC",
  };

  if (options.search !== "") payload.search = options.search

  if (options?.skipPagination) {
    payload = {
      ...payload,
      skipPagination: true,
      pageNo: options?.pageNo ?? 1,
      limit: options?.pageSize ?? 50,
    };
  } else {
    payload = { ...payload, pageNo: options?.pageNo, limit: options.pageSize };
  }

  let response = await new Client({
    path: "/player/template/list",
    payload,
  }).post();

  const { items } = response?.data;

  // const template = {
  //   _id: "670c2622524875987083745a",
  //   cardId: "207dfb02ca1e0fbc8aa1ffe7895948f0",
  //   playerId: 431214,
  //   fullName: "Ezri Konsa",
  //   commonName: "E. Konsa",
  //   displayName: "Konsa",
  //   displayNameAbb: "Konsa",
  //   club: "Aston Villa",
  //   league: "Premier League (England)",
  //   preferredPosition: "CB",
  //   secondaryPosition1: "RB",
  //   secondaryPosition2: null,
  //   nationality: "England",
  //   attackRating: 79.8447,
  //   defenseRating: 81.2764,
  //   creativityRating: 79.6202,
  //   overallRating: 81.11077999999999,
  //   deactivated: null,
  //   sentDate: null,
  // };

  const updatedList = items?.map((el) => {
    return {
      ...el,
      id: el.cardId,
      label: el.fullName,
      attackRating: setNumberFormat(el?.attackRating, "0"),
      defenseRating: setNumberFormat(el?.defenseRating, "0"),
      creativityRating: setNumberFormat(el?.creativityRating, "0"),
      overallRating: setNumberFormat(el?.overallRating, "0"),
    };
  });

  let returnData = {
    ...response.data,
    items: updatedList,
  };

  return returnData;
}

async function getPlayerCards(params) {
  let payload = {
    pageNo: params?.pageNo ?? 1,
    limit: params.pageSize ?? 10,
    sortKey: params.sortBy ?? "createdAt",
    sortDir: params?.sortDir ?? "DESC",
    search: params?.search ?? "",
  };

  if (params?.skipPagination) payload["skipPagination"] = true
  if (params.nationality !== "all" && params?.nationality) payload["nationality"] = params?.nationality;
  if (params.club !== "all" && params?.club) payload["club"] = params?.club;

  let response = await new Client({
    path: "/player/card/list",
    payload,
  }).post();

  const { items } = response?.data;

  // const updatedList = items?.map((el) => {
  //   return {
  //     ...el,
  //     attackRating: setNumberFormat(el?.attackRating, "2"),
  //     defenseRating: setNumberFormat(el?.defenseRating, "2"),
  //     creativityRating: setNumberFormat(el?.creativityRating, "2"),
  //     overallRating: setNumberFormat(el?.overallRating, "2"),
  //   };
  // });

  let returnData = {
    message: response.message,
    ...response.data,
    items,
  };

  return returnData;
}

async function getPositions(options) {
  try {
    let path = `/position/list`;

    if (options?.search) path += `&search=${options.search}`;

    const response = await new Client({ path }).post();

    return response;
  } catch (error) {
    throw error;
  }
}

async function getClubs(options) {
  try {
    let path = `/club/list`;

    const response = await new Client({ path }).post();

    return response;
  } catch (error) {
    throw error;
  }
}

async function getInfoClub(id) {
  try {
    const response = await new Client({ path: `/club/info`, payload: { clubId: id } }).post();
    return response;
  } catch (error) {
    throw error;
  }
}

async function getInfoPosition(id) {
  try {
    const response = await new Client({ path: `/position/info`, payload: { positionId: id } }).post();
    return response;
  } catch (error) { throw error; }

}

async function createClub(payload) {
  try {
    const response = await new Client({ path: `/club/create`, payload }).post();

    return response;
  } catch (error) {
    throw error;
  }
}

async function updateClub(payload) {
  try {
    const response = new Client({
      path: `/club/update`,
      payload,
    }).post();

    return response;
  } catch (error) {
    throw error;
  }
}

async function updatePosition(payload, id) {
  try {
    const response = new Client({
      path: `/position/update`,
      payload,
    }).post();
    return response;
  } catch (error) {
    throw error;
  }
}

async function createPosition(payload) {
  try {
    const response = new Client({ path: `/position/create`, payload }).post();
    return response;
  } catch (error) {
    throw error;
  }
}

async function getManualPlayerCards(params) {
  let payload = {
    pageNo: params?.pageNo ?? 1,
    limit: params.pageSize ?? 10,
    sortKey: params.sortBy ?? "createdAt",
    sortDir: params?.sortDir ?? "DESC",
    search: params?.search ?? "",
  };

  if (params?.skipPagination) payload["skipPagination"] = true
  if (params.nationality !== "all" && params?.nationality) payload["nationality"] = params?.nationality;
  if (params.club !== "all" && params?.club) payload["club"] = params?.club;

  let response = await new Client({
    path: "/player/manual/list",
    payload,
  }).post();

  const { items } = response?.data;

  const updatedList = items?.map((el) => {
    return {
      ...el,
      id: el.playerId,
      label: el.fullName,
      attackRating: setNumberFormat(el?.attackRating, "0"),
      defenseRating: setNumberFormat(el?.defenseRating, "0"),
      creativityRating: setNumberFormat(el?.creativityRating, "0"),
      overallRating: setNumberFormat(el?.overallRating, "0"),
    };
  });

  let returnData = {
    message: response.message,
    ...response.data,
    items: updatedList,
  };

  return returnData;
}

async function getPlayerCardUpdates(options) {
  let payload = {
    pageNo: options?.pageNo ?? 1,
    limit: options.pageSize ?? 10,
    sortKey: options.sortBy ?? "createdAt",
    sortDir: options?.sortDir ?? "DESC",
  };

  if (options.search !== "") payload.search = options.search

  if (options?.skipPagination) {
    payload = {
      ...payload,
      skipPagination: true,
      pageNo: options?.pageNo ?? 1,
      limit: options?.pageSize ?? 50,
    };
  } else {
    payload = { ...payload, pageNo: options?.pageNo, limit: options.pageSize };
  }

  let response = await new Client({
    path: "/player/updates/list",
    payload,
  }).post();

  const { items } = response?.data;


  let returnData = {
    message: response.message,
    ...response.data,
    items,
  };

  return returnData;
}

export {
  BoosterSlotOptions,
  CARD_TYPE_OPTIONS,
  JERSY_COLOR_TYPE_OPTIONS,
  COUNTRY_OPTIONS,
  jerseySizeOptions,
  uploadPlayerCardImage,
  playerCardInfo,
  createPlayerCard,
  updatePlayerCard,
  getPlayerCardTemplate,
  getPlayerCards,
  getClubs,
  getPositions,
  createClub,
  createPosition,
  updatePosition,
  updateClub,
  getInfoClub,
  getInfoPosition,
  getManualPlayerCards,
  getPlayerCardUpdates
};

