import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      height: 39,
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  icon: {
    [theme.breakpoints.down("sm")]: {
      fontSize: `14px !important`,
    },
  }
}));
const CustomButton = ({ startIcon, onClick, children }) => {
  const styles = useStyles();
  return (
    <Button
      variant="contained"
      className={styles.button}
      onClick={onClick}
      startIcon={startIcon ?? <AddIcon className={styles.icon} />}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
