import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import ModalContainer from "components/ModalContainer";
import ModalLayout from "components/ModalLayout";
import { buttonClasses } from "@mui/base/Button";
import PlayerCardCanvas from "components/PlayerCardCanvas";
import { sendMessageToCanvas } from "utils/miscUtils";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import CustomTabPanel from "components/CustomTabPanel";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { Tabs } from "@mui/base/Tabs";

const useStyles = makeStyles((theme) => ({
  modalConatiner: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "start",
    alignItems: "center",
  },
  header: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const playerCardTab = 0,
  playerCardDetailsTab = 1;

const ViewFullCardModal = ({ cardDetails, isOpen, label, close }) => {
  const styles = useStyles();
  const [tab, setTab] = useState(playerCardTab);
  console.log({ tab });
  const onTabChange = (e, v) => setTab(v);

  const sendToCanvas = (type, data, message) => {
    const iframe = document.getElementById("playerCard");

    if (!iframe) {
      console.log("playerCard iframe is not found");
      return;
    }

    sendMessageToCanvas(iframe, {
      message: message ?? "UPDATE_PLAYER",
      type: type === "INIT" ? "INIT" : type,
      data: data,
    });
  };

  useEffect(() => {
    // let timer
    // if (tab === 1) {
    //   timer = setTimeout(()=> sendToCanvas("PLAYER_DETAILS", cardDetails, "VIEW_PLAYER_DETAILS"), 100);
    // }
    // return () => clearTimeout(timer)
  }, [tab]);

  if (!cardDetails) return <></>;

  return (
    <ModalContainer openModel={isOpen} handleClose={close}>
      <ModalLayout isLargeContainer={true} padding={35}>
        <Box className={styles.modalConatiner}>
          {/* <Typography>{label}</Typography>
          <PlayerCardCanvas playerDetails={cardDetails} sendToCanvas={sendToCanvas}/> */}

          <Tabs defaultValue={playerCardTab} onChange={onTabChange}>
            <TabsList>
              <Tab label="Player Card" value={playerCardTab}>
                Player Card
              </Tab>
              <Tab label="Player Card Details" value={playerCardDetailsTab}>
                Player Card Details
              </Tab>
            </TabsList>
            <TabPanel value={playerCardTab} index={playerCardTab}>
              <PlayerCardCanvas
                playerDetails={cardDetails}
                sendToCanvas={sendToCanvas}
              />
            </TabPanel>
            <TabPanel value={playerCardDetailsTab} index={playerCardDetailsTab}>
              <PlayerCardCanvas
                playerDetails={cardDetails}
                sendToCanvas={
                  // sendToCanvas
                  () => sendToCanvas("INIT", cardDetails, "VIEW_PLAYER_DETAILS")
                }
              />
            </TabPanel>
          </Tabs>
        </Box>
      </ModalLayout>
    </ModalContainer>
  );
};

const Tab = styled(BaseTab)(
  ({ theme }) => `
  font-family: "IBM Plex Sans", sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  line-height: 1.5;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${theme.palette.secondary.main};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${theme.palette.secondary.main};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${theme.palette.primary.main};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
);

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 400px;
  background-color: ${theme.palette.primary.main};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  place-content: space-between center;
  box-shadow: 0 4px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.4)" : "rgba(0,0,0, 0.2)"
  };
  `
);

export default ViewFullCardModal;
