import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContentShell from "components/ContentShell";
import SearchInput from "components/SearchInput";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { opacity2hex } from "utils/miscUtils";
import configData from "config/config.json";
import { useSnackbar } from "hooks/useSnackbar";
import usePaginationHook from "hooks/usePagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getBannerTableColums } from "utils/tableHelper/bannerTableHelper";
import sessionHelper from "services/sessionHelper";
import { getBanners, updateBanner } from "services/bannerService";
import { PermittedRoles } from "utils/permissions";
import CustomButton from "components/CustomButton";
import ViewImageModal from "screens/modals/ViewImageModal";
import useOpenClose from "hooks/useOpenClose";

const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: theme.palette.common.offWhite + opacity2hex("0.80"),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  container: {
    padding: "12px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const RowActions = ({
  rowId,
  data,
  actionHandlers: { handleDisble = () => {} },
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowAction = (data) => {
    setAnchorEl(null);
    navigate(configData.routes.banners.edit?.replace(":id", data?._id));
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleRowAction(data)}>Edit</MenuItem>
        <MenuItem onClick={() => handleDisble(data)}>Disable</MenuItem>
      </Menu>
    </>
  );
};

const defaultPageSize = 10,
  defaultPageNo = 1;

const Banner = () => {
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [columns, setColumns] = useState([]);
  const [listData, setListData] = useState([]);
  const { isOpen, close, open, extraData, updateExtra } = useOpenClose();

  const onSearch = (value) => {
    setSearchValue(value);
    onPageSizeChange({ target: { value: defaultPageSize } });
    fetchData(value);
  };

  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.BANNERS.UPDATE_ROLES
  );

  const createBrand = () => navigate(configData.routes.banners.create);

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({
      totalItems: listData?.length,
      defaultPageNo,
      defaultPageSize,
    });

  const handleDisble = async (data) => {
    try {
      // disable banner
      console.log({ data });
      const { _id, ...rest } = data;
      const resp = await updateBanner({
        ...rest,
        fileType: "png/",
        isActive: false,
      }, _id);
      showSnackbar("Banner disabled successfully");
      fetchData();
    } catch (error) {
      showSnackbar(error.message ?? "Error on disable banner", "error");
    }
  };

  const fetchData = async (_search) => {
    setLoading(true);
    try {
      // feth banners data
      const resp = await getBanners({ pageNo, pageSize, searchValue: _search });

      setListData(
        resp?.data.map((item) => ({ ...item, isActive: `${item.isActive}` }))
      );
      showSnackbar(resp?.message, "success");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  const openModel = (data) => {
    // console.log({data})
    updateExtra({
      imageUrl:
        "https://pbf-dev.s3.amazonaws.com/playerJerseys-clubId/@1x_webp/Men/5af2e442-9d27-415c-90e5-df3350fc9a4e/18.webp",
    });
    open();
  };

  useEffect(() => {
    const cols = getBannerTableColums().filter(
      (col) => col.id !== "actions" || hasEditPermission
    );
    setColumns(cols);
    fetchData();
  }, [pageNo, pageSize]);

  return (
    <ContentShell
      title="Banners"
      loading={loading}
      actions={
        <Box style={{ display: "flex", flexFlow: "row nowrap", gap: 4 }}>
          <SearchInput
            value={searchValue}
            placeholder="Search a banner"
            onSearch={onSearch}
          />
          {sessionHelper.hasRole(PermittedRoles.BANNERS.CREATE_ROLES) && (
            <CustomButton onClick={createBrand}>Create</CustomButton>
          )}
        </Box>
      }
    >
      <Box className={styles.container}>
        <Table
          columns={columns}
          data={listData}
          currentPage={pageNo}
          pageSize={pageSize}
          totalEntries={listData.length}
          highlightAltRow={true}
          actions={RowActions}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
          actionHandlers={{
            openModal: openModel,
            handleDisble: handleDisble,
          }}
        />
        <ViewImageModal
          label="Banner Image"
          imageSource={extraData.imageUrl}
          isOpen={isOpen}
          close={close}
        />
      </Box>
    </ContentShell>
  );
};

export default Banner;
