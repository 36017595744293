import { Client } from "./_clientService";

async function getTFGPlayers(params) {
  const resp = await new Client({ path: "" }).get();

  return resp.data;
}

async function getPBFPlayers(params) {
  const resp = await new Client({ path: "" }).get();
  return resp.data;
}

async function getManualPlayerInfo(cardId) {
  const resp = await new Client({
    path: "/player/manual/info",
    payload: { cardId },
  }).post();
  return resp;
}

export { getTFGPlayers, getPBFPlayers, getManualPlayerInfo };
