import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { defaultPageNo, defaultPageSize } from "utils/constants";
import { updateQueryParams } from "utils/queryParamsHelper";

const usePaginationHook = ({ totalItems }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPageNo = Number(searchParams.get("page")) || defaultPageNo;
  const initialPageSize = Number(searchParams.get("pageSize")) || defaultPageSize;

  const [pageNo, setPageNo] = useState(initialPageNo);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const onChangePage = (_pageNo) => {
    setPageNo(_pageNo);
    updateQueryParams({ pageNo: _pageNo, pageSize }, setSearchParams);
  };

  const onPageSizeChange = (event) => {
    const newPageSize = Number(event?.target?.value ?? defaultPageSize);
    setPageSize(newPageSize);
    setPageNo(1); // Reset to first page when page size changes
    updateQueryParams({ pageNo: 1, pageSize: newPageSize }, setSearchParams);
  };

  const onPrev = () => {
    if (pageNo > 1) {
      onChangePage(pageNo - 1);
    }
  };

  const onNext = () => {
    const maxPage = Math.ceil(totalItems / pageSize);
    if (pageNo < maxPage) {
      onChangePage(pageNo + 1);
    }
  };

  return {
    onChangePage,
    onPageSizeChange,
    onPrev,
    onNext,
    pageNo,
    pageSize,
  };
};

export default usePaginationHook;
