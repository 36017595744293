/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import debounce from "lodash.debounce";
import { styled, alpha } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.grey, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.grey, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#00000 !important",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const ClearButtonWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  height: "100%",
  color: "#4d4d4d",
}));

const SearchInput = ({ value, placeholder = "Search...", onSearch }) => {
  const [query, setQuery] = useState(value);

  const debouncedSearch = useCallback(
    debounce((searchQuery) => onSearch(searchQuery), 600),
    [onSearch]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    debouncedSearch(value);
  };

  const handleClear = () => {
    setQuery("");
    onSearch("");
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon style={{ color: "#4d4d4d" }} />
      </SearchIconWrapper>
      <StyledInputBase
        fullWidth
        autoFocus
        placeholder={placeholder}
        value={query}
        onChange={handleInputChange}
      />
      {query && (
        <ClearButtonWrapper>
          <IconButton size="small" onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        </ClearButtonWrapper>
      )}
    </Search>
  );
};

export default SearchInput;
