function clubTableColumns() {
  return [
    {
      id: "name",
      label: `Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "abbreviation",
      label: `Abbreviation Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "",
    },
    {
      id: "textColor",
      label: `Text Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "",
    },
    {
      id: "backgroundColor",
      label: `Background Color`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "",
    },
    {
      id: "externalClubId",
      label: `External ID`,
      type: "string",
      isSortable: false,
      alignHead: "center",
      alignBody: "center",
    },
    { id: "actions", label: "Actions" },
  ];
}

function playerCardsListColumn(data = {}) {

  const { showActions = true, showPreview = true } = data;

  const cols = [
    {
      id: "playerId",
      label: `Player ID`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "fullName",
      label: `Full Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "displayName",
      label: `Display Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "commonNameForename",
      label: `First name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "commonNameSurname",
      label: `Surname`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "club",
      label: `Club`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "league",
      label: `League`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "preferredPosition",
      label: `Position`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Preferred Position",
    },
    {
      id: "nationality",
      label: `Country`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Preferred Position",
    },
    {
      id: "attackRating",
      label: `ATT`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Attack Rating",
    },
    {
      id: "defenseRating",
      label: `DEF`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Defense Rating",
    },
    {
      id: "creativityRating",
      label: `CRE`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Creativity Rating",
    },
    {
      id: "overallRating",
      label: `OVR`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
      tooltip: "Overall Rating",
    },
    {
      id: "rarity",
      label: `Rarity`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
  ];

  if (showPreview) {
    cols.push({ id: "thumbnail", label: "Preview" });
  }

  if (showActions) {
    cols.push({ id: "actions", label: "Actions" })
  }

  return cols;
}

export { clubTableColumns, playerCardsListColumn };
