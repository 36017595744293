import { blobToBinary } from "utils/miscUtils";

async function uploadImage(params) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", params, params?.fileType ?? "image/png");

    const file = await blobToBinary(params.data);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    const response = fetch(params?.url, requestOptions);

    return response;
  } catch (error) {
    throw error;
  }
}

export { uploadImage };
