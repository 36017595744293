import ContentShell from "components/ContentShell";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import configData from "config/config.json";
import { useSnackbar } from "hooks/useSnackbar";
import useStyles from "styles/banner/bannerEditor";
import { SimpleTextInput } from "components/FormTextInput";
import {
  createBanner,
  getBannerInfo,
  updateBanner,
} from "services/bannerService";
import FilePicker from "components/FilePicker";
import * as Yup from "yup";
import classNames from "classnames";
import { uploadImage } from "services/uploadService";

const _bannerDetails = {
  title: "",
  navigationPath: "",
  body: "",
  fileType: "png/",
};

const bannerValidation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  navigationPath: Yup.string().required("Navigation Path is required"),
  body: Yup.string().required("Body is required"),
});

const BannerEditor = () => {
  const styles = useStyles();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [bannerDetails, setBannerDetails] = useState(_bannerDetails);
  const [image, setImage] = useState(null);

  const cancel = () => navigate(configData.routes.banners.banners);

  const submit = async () => {
    try {
      setLoading(true);
      let response;
      await bannerValidation.validate(bannerDetails);
      if (id === "create") {
        response = await createBanner({
          ...bannerDetails,
          isActive: true,
        });

        if (response?.data?.fileUploadUrl) {
          const fileUploadUrl = response?.data?.fileUploadUrl;
          await upload(fileUploadUrl);
        }
      } else {
        response = await updateBanner(
          {
            title: bannerDetails.title,
            navigationPath: bannerDetails.navigationPath,
            body: bannerDetails.body,
          },
          id
        );
      }
      showSnackbar(response.message, "success");
      setLoading(false);
      navigate(configData.routes.banners.banners);
    } catch (error) {
      if (error?.inner)
        error.inner.forEach((error) => showSnackbar(error.message, "error"));
      showSnackbar(error.message, "error");
      setLoading(false);
    }
  };

  const onChange = async (e) => {
    const { name, value } = e?.target;
    setBannerDetails((state) => ({
      ...state,
      [name]: name === "isActive" ? value === "true" : value,
    }));
  };

  const upload = async (url) => {
    try {
      const resp = await uploadImage({
        data: image,
        url,
      });
      if (resp.status === 200)
        showSnackbar(resp.message ?? "Image Uploaded", "success");
      return resp;
    } catch (error) {
      showSnackbar(error.message || "Error uploading image!");
    }
  };

  const onSelectImage = (image) => {
    setImage(image);
  };

  const fetchBannerInfo = async () => {
    try {
      setLoading(true);
      const resp = await getBannerInfo(id);
      console.log(resp);
      setBannerDetails(resp.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message, "error");
    }
  };

  useEffect(() => {
    if (id !== "create") fetchBannerInfo();
  }, []);

  return (
    <ContentShell
      showBackButton
      onBackButtonClick={cancel}
      title={`${id === "create" ? "Create" : "Edit"} Banner`}
      loading={loading}
      actions={
        <Box className={styles.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={submit}>
            Save
          </Button>
        </Box>
      }
    >
      <Box className={styles.container}>
        <Box className={styles.firstFormContainer}>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Title"
              name="title"
              value={bannerDetails.title}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="NavigationPath"
              name="navigationPath"
              value={bannerDetails.navigationPath}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Body"
              name="body"
              value={bannerDetails.body}
              onChange={onChange}
            />
          </Box>

          <Box
            className={classNames(styles.imagePickerContainer, {
              [styles.hide]: id !== "create",
            })}
          >
            <FilePicker
              value={image}
              name="bannerImage"
              onFileSelect={onSelectImage}
            />
          </Box>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default BannerEditor;
