import { superAdmin } from "utils/permissions";
import { Client } from "./_clientService";
import sessionHelper from "./sessionHelper";
import { getPlatforms } from "./platformService";
import { getBrands } from "./brandServices";
import { getOperators } from "./operatorService";
import { getClubs, getPositions } from "./playerCardService";

async function signIn({ email, password }) {
  const result = await new Client({
    path: "/user/login",
    payload: { email: email.trim(), password: password.trim() },
  }).post();

  sessionHelper.onSignIn({
    email: result?.data?.user?.email,
    role: result?.data?.user?.role,
    name: result?.data?.user?.username,
    sid: result?.data?.token,
    isActive: result?.data?.user?.isActive,
    id: result?.data?.user?.id,
    lastLogin: result?.data?.user?.lastLogin,
  });

  await onCreateSession();

  return true;
}

async function onCreateSession() {
  try {
    const ops = [
      //call this API whenever user POB is updated
      new Client({ path: `/countries/list` }).get(),
      new Client({ path: `/misc/ovrFormula` }).get(),
      getClubs({ skipPagination: true }),
      getPositions({ skipPagination: true }),
    ];

    if (sessionHelper.role === superAdmin) {
      let _ops = [
        getPlatforms({ pageSize: 50, pageNo: 1 }),
        getOperators({ pageSize: 50, pageNo: 1 }),
        getBrands({ pageSize: 50, pageNo: 1 }),
      ];
      ops.push(..._ops);
    }
    const response = await Promise.all(ops);

    sessionHelper.onCreateSession({
      countries: response[0]?.data,
      ovrFormula: response[1]?.data,
      clubs: response[2]?.data,
      positions: response[3]?.data,
      platforms: response[4]?.data.platforms,
      operators: response[5]?.data.operators,
      brands: response[6]?.data.brands,
    });
    return response;
  } catch (e) {
    signOut();
  }
}

async function restore() {
  if (!sessionHelper.isLoggedIn()) return { error: true };
  sessionHelper.setUserInfo();
  await onCreateSession();

  return { error: false };
}

function signOut() {
  localStorage.removeItem("userInfo");
  sessionHelper.onSignOut();
}

export { signIn, signOut, restore, onCreateSession };
