/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Typography } from "@mui/material";
import ContentShell from "components/ContentShell";
import { SimpleSelectInput } from "components/FormSelectInput";
import { SimpleTextInput } from "components/FormTextInput";
import { useLoadingOverlay } from "components/LoadingOverlay";
import { useSnackbar } from "hooks/useSnackbar";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import configData from "config/config.json";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import useStyles from "styles/league/leagueEditor";
import {
  leagueInfo,
  createLeagueAndMileStone,
  updateLeagueAndMileStone,
} from "services/leagueServices";
import { delay } from "utils/miscUtils";
import SearchableInput from "components/SearchableInput";
import {
  packInfo as getPackInfo,
  getPlayerPacks as getRewardPacks,
} from "services/packServices";
import { leagueSchema } from "utils/validation";

const milestoneTemplate = {
  number: 1,
  steps: 1,
  rewardPack: null,
  rewardCoins: 0,
  k1: null,
  k2: null,
};

const initialValue = {
  name: "",
  number: 1,
  k1: 1,
  k2: 1,
  // endOfCycleRewardPack: 1,
  // endOfCycleRewardAmt: 0,
  milestones: [milestoneTemplate],
};

const defaultPageSize = 20,
  defaultPageNo = 1;

const MileStoneEditor = ({
  id,
  index,
  onMilestoneChange,
  milestone,
  rewardOptions,
  setRewardOptions,
  onSearch,
}) => {
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();
  const [isInfoFetching, setInfoFetching] = useState(false);

  const onChange = (e) => onMilestoneChange(e, index);

  const getRewardPackInfo = async (rewardPack) => {
    try {
      setInfoFetching(true);
      const resp = await getPackInfo(rewardPack);
      if (resp?.data) {
        setRewardOptions((state) => ({
          ...state,
          rewardOptions: [
            ...state.rewardOptions,
            { id: resp?.data?._id, label: resp?.data?.name },
          ],
        }));
      }
      setInfoFetching(false);
    } catch (error) {
      setInfoFetching(false);
      showSnackbar(error.message);
    }
  };

  useEffect(() => {
    let isOptionPresent = rewardOptions.find(
      (rewardOp) => rewardOp.id === milestone.rewardPack
    );

    if (id !== "create" && milestone.rewardPack && !isOptionPresent)
      getRewardPackInfo(milestone.rewardPack);
  }, []);

  return (
    !isInfoFetching && (
      <Box className={styles.milestoneRoot}>
        <Typography className={styles.milestoneHeaderText}>
          Milestone-{index + 1}
        </Typography>
        <Box className={styles.milestoneInputContainer}>
          <Box className={styles.field}>
            <SearchableInput
              selected={
                id !== "create"
                  ? rewardOptions.find(
                      (rewardOp) => rewardOp.id === milestone.rewardPack
                    )
                  : milestone.rewardPack
              }
              name="rewardPack"
              options={rewardOptions}
              label="Reward Packs"
              placeholder="search packs"
              onInputChange={onSearch}
              onOptionSelect={(e) => onMilestoneChange(e, index)}
              customStyle={{ width: "100%" }}
            />
          </Box>
          <Box className={styles.smallField}>
            <SimpleTextInput
              type="number"
              label="Number *"
              name="number"
              value={milestone?.number}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.smallField}>
            <SimpleTextInput
              type="number"
              label="Steps *"
              name="steps"
              value={milestone?.steps}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.smallField}>
            <SimpleTextInput
              type="number"
              label="Reward Coins"
              name="rewardCoins"
              value={milestone?.rewardCoins}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.smallField}>
            <SimpleTextInput
              type="number"
              label="K1 (optional)"
              name="k1"
              value={milestone?.k1}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.smallField}>
            <SimpleTextInput
              type="number"
              label="K2 (optional)"
              name="k2"
              value={milestone?.k2}
              onChange={onChange}
            />
          </Box>
          {index !== 0 && (
            <DeleteIcon
              className={styles.delete}
              onClick={() => onChange({ target: { name: "delete" } })}
            />
          )}
        </Box>
        <Divider />
      </Box>
    )
  );
};

const LeagueEditor = () => {
  const styles = useStyles();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();
  const [leagueDetails, setLeagueDetails] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [handleEvents, setHandleEvents] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [options, setOptions] = useState({
    leagueOptions: Array.from({ length: 10 }, (v, k) => ({
      id: `${k + 1}`,
      label: `${k + 1}`,
    })),
    rewardOptions: [],
  });
  const [searchPagination, setSearchPagination] = useState({
    pageNo: defaultPageNo,
    pageSize: defaultPageSize,
    hasMore: false,
  });

  const generateOptions = (items) =>
    items.map((item, index) => ({
      id: item?._id,
      label: item?.name,
    }));

  const convertToPayload = (league) => {
    // modify payload here
    return {
      ...league,
      milestones:
        league?.milestones?.map((stone) => {
          return {
            ...stone,
            rewardPack: stone.rewardPack?.id || stone.rewardPack,
          };
        }) || [],
    };
  };

  const submit = async () => {
    showLoadingOverlay();
    const payload = convertToPayload(leagueDetails);
    try {
      let resp;

      await leagueSchema.validate(leagueDetails);

      const tempLeagueDetails = JSON.parse(JSON.stringify(leagueDetails));

      for (
        let index = 0;
        index < tempLeagueDetails.milestones.length;
        index++
      ) {
        const milestone = tempLeagueDetails.milestones[index];

        if (milestone.rewardPack) {
          tempLeagueDetails.milestones[index].rewardPack =
            tempLeagueDetails.milestones[index].rewardPack.id;
        }
      }

      if (id === "create") {
        resp = await createLeagueAndMileStone(payload);
      } else {
        resp = await updateLeagueAndMileStone(payload, id);
      }
      showSnackbar(resp.message, "success");
      delay(3000);
      hideLoadingOverlay();
      navigate(configData.routes.leagues.leagues);
    } catch (error) {
      console.log(error);

      if (error?.inner) {
        error.inner.forEach((error) => {
          showSnackbar(error.message, "error");
        });
      }

      hideLoadingOverlay();
      showSnackbar(error?.message);
    }
  };

  const cancel = () => navigate(configData.routes.leagues.leagues);

  const handleNumberFieldChange = ({ fieldName, value, _data }, flag) => {
    let data = { ..._data };
    const _value = parseInt(value) ?? null; //Number(value?.split(".")[0]) ?? "";
    let updatedData = {
      ...data,
      [fieldName]: _value,
    };

    if (flag) updatedData["name"] = `League_${value}`;

    return updatedData;
  };

  const onChange = (e, leagueFlag) => {
    const { name, value } = e?.target;
    let data = { ...leagueDetails };

    const eventHandler = handleEvents[name];

    if (eventHandler) {
      const resp = eventHandler(
        { fieldName: name, value, _data: data },
        leagueFlag
      );

      data = resp;
    } else {
      data = {
        ...data,
        [name]: value,
      };
    }

    setLeagueDetails(data);
  };

  const onMilestoneChange = (e, index) => {
    const { name, value } = e?.target;

    let milestones = [...leagueDetails.milestones];

    const eventHandler = handleEvents[name];

    if (name === "delete") {
      milestones = milestones.filter((_, idx) => idx !== index);
    } else if (eventHandler) {
      milestones = [
        ...leagueDetails.milestones?.slice(0, index),
        {
          ...eventHandler({
            fieldName: name,
            value,
            _data: leagueDetails.milestones[index],
          }),
        },
        ...leagueDetails.milestones?.slice(index + 1),
      ];
    } else {
      milestones = [
        ...leagueDetails.milestones?.slice(0, index),
        {
          ...leagueDetails.milestones[index],
          [name]: value,
        },
        ...leagueDetails.milestones?.slice(index + 1),
      ];
    }

    setLeagueDetails((_state) => ({ ..._state, milestones }));
    setOptions((st) => ({ ...st, rewardOptions: generateOptions(rewards) }));
  };

  const add = () => {
    setLeagueDetails((_state) => {
      let milestones = [..._state.milestones, milestoneTemplate];
      return { ..._state, milestones };
    });
  };

  const removeAll = () =>
    setLeagueDetails((_state) => ({
      ..._state,
      milestones: [milestoneTemplate],
    }));

  const fetchLeagueInfo = async () => {
    setLoading(true);
    try {
      const resp = await leagueInfo(id);
      setLeagueDetails(resp?.data);
      setLoading(false);
      showSnackbar(resp?.message, "success");
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  const fetchRewardPacks = async (filters, init) => {
    try {
      const resp = await getRewardPacks(filters);
      init && setRewards(resp?.items);
      setOptions((st) => ({
        ...st,
        rewardOptions: generateOptions(resp.items),
      }));
    } catch (error) {
      showSnackbar(error?.message || "Failed to fetch reward packs");
    }
  };

  const onSearchChange = async (searchValue) => {
    if (searchValue) {
      fetchRewardPacks({
        search: searchValue,
        skipPagination: true,
        ...searchPagination,
      });
    } else
      setOptions((st) => ({ ...st, rewardOptions: generateOptions(rewards) }));
  };

  useEffect(() => {
    fetchRewardPacks(searchPagination, true);
    if (id !== "create") fetchLeagueInfo();
    const eventsMaps = {
      k1: handleNumberFieldChange,
      k2: handleNumberFieldChange,
      steps: handleNumberFieldChange,
      number: handleNumberFieldChange,
      rewardCoins: handleNumberFieldChange,
    };

    setHandleEvents(eventsMaps);
  }, []);

  return (
    <ContentShell
      showBackButton
      onBackButtonClick={() => navigate(-1)}
      title={`${id === "create" ? "Create" : "Edit"} League & Milestones`}
      loading={loading}
      actions={
        <Box className={styles.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={submit}>
            Save
          </Button>
        </Box>
      }
    >
      <Box className={styles.container}>
        <Box className={styles.formContainer}>
          <Box className={styles.field}>
            <SimpleSelectInput
              name="number"
              label="League Number"
              value={leagueDetails.number}
              options={options.leagueOptions}
              onChange={(e) => onChange(e, true)}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              type="text"
              label="League Name"
              name="name"
              value={leagueDetails.name}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              type="number"
              label="K1 *"
              name="k1"
              value={leagueDetails.k1}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              type="number"
              label="K2 *"
              name="k2"
              value={leagueDetails.k2}
              onChange={onChange}
            />
          </Box>
        </Box>
        <Box className={styles.milestoneContainer}>
          <Box className={styles.milestoneButtonConatiner}>
            <Typography className={styles.milestoneText}>Milestones</Typography>
            <Box className={styles.buttonContainer}>
              <Button
                size="small"
                type="submit"
                variant="contained"
                onClick={add}
              >
                <AddIcon color="white" />
                <span style={{ marginLeft: 5 }}>{"add"}</span>
              </Button>
              <Button
                size="small"
                type="submit"
                variant="contained"
                onClick={removeAll}
              >
                <DeleteForeverIcon color="white" />
                <span style={{ marginLeft: 5 }}>{"delete"}</span>
              </Button>
            </Box>
          </Box>

          <Divider />
          <Box className={styles.milestoneFormContainer}>
            {leagueDetails?.milestones?.map((milestone, k) => {
              return (
                <MileStoneEditor
                  key={k}
                  id={id}
                  index={k}
                  milestone={milestone}
                  onSearch={onSearchChange}
                  rewardOptions={options.rewardOptions}
                  setRewardOptions={setOptions}
                  onMilestoneChange={onMilestoneChange}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default LeagueEditor;
