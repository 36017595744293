export const updateQueryParams = (newParams, _setSearchParams) => {
  _setSearchParams((prevParams) => {
    const updatedParams = new URLSearchParams(prevParams);
    Object.entries(newParams).forEach(([key, value]) => {
      updatedParams.set(key, value);
    });
    return updatedParams;
  });
};

export const getQueryParam = (param) => {
  const searchParams = new URLSearchParams();
  return searchParams.get(param);
};
