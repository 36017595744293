import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import ModalContainer from "components/ModalContainer";
import ModalLayout from "components/ModalLayout";

const useStyles = makeStyles((theme) => ({
  modalConatiner: {
    height: "100%",
    width: "100%",
    margin: "5px",
    displa: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const ViewImageModal = ({ isOpen, label, imageSource, close }) => {
  const styles = useStyles();

  return (
    isOpen && (
      <ModalContainer openModel={isOpen} handleClose={close}>
        <ModalLayout isLargeContainer={true}>
          <Box className={styles.modalConatiner}>
            <Typography>{label}</Typography>
            <Box style={{ margin: 10 }}>
              <img
                className={styles.image}
                src={imageSource}
                alt={label + " "}
              />
            </Box>
          </Box>
        </ModalLayout>
      </ModalContainer>
    )
  );
};

export default ViewImageModal;
