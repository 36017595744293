import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import TableContainer from "@mui/material/Paper";
import MuiTable from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import Pagination from "./Pagination";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { SimpleTextInput } from "components/FormTextInput";
import { Tooltip, Typography } from "@mui/material";
import { opacity2hex } from "utils/miscUtils";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    fontWeight: "600 !important",
    backgroundColor: "#1d1030",
    fontSize: 12,
    padding: 4,
    color: "#D3D3D3",
  },
  sortLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  headAlignRight: {
    justifyContent: "flex-end !important",
    textAlign: "center",
  },
  headAlignCenter: {
    justifyContent: "center !important",
    textAlign: "center",
  },
  icon: {
    marginLeft: 2,
    fontSize: 18,
  },
  tableSubHead: {
    fontWeight: "700 !important",
    fontSize: 12,
    padding: 8,
    backgroundColor: theme.palette.common.offWhite,
  },
  bodyCell: {
    padding: "8px 8px 8px 6px",
    maxHeight: "120px !important",
    overflowX: "auto",
  },
  bodyCellContent: {
    fontSize: 12,
    fontWeight: "500 !important",
  },
  padding: {
    padding: "8px 10px 8px 6px",
  },
  borderRight: {
    borderRight: 1,
    // borderWidth: 0,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: "#45454510",
  },
  grey: {
    // backgroundColor: theme.palette.common.offWhite,
    backgroundColor: "#d3d3d3" + opacity2hex("0.30"),
  },
  modalPreview: {
    height: 50,
    cursor: "pointer",
    alignContent: "center",
    color: theme.palette.primary.main,
  },
}));

const Head = ({ columns, onColumnClick, totalData, highlightAltRow }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, _index) => (
          <Tooltip
            key={_index}
            title={column.tooltip || ""}
            placement="top-start"
            arrow
          >
            <TableCell
              key={column.id || `head-${_index}`}
              // align={column?.alignHead || "left"}
              className={classNames(classes.tableHead, {
                [classes.borderRight]: highlightAltRow,
              })}
              onClick={() => onColumnClick(column)}
            >
              <Box
                className={classNames(classes.sortLabel, {
                  [classes.headAlignRight]: column?.alignHead === "right",
                  [classes.headAlignCenter]: column?.alignHead === "center",
                })}
              >
                {column.label}
                {column?.isSortable && (
                  <SwapVertIcon className={classes.icon} />
                )}
              </Box>
            </TableCell>
          </Tooltip>
        ))}
      </TableRow>

      {totalData?.data && (
        <TableRow>
          <TableCell
            className={classNames(
              {
                [classes.borderRight]: highlightAltRow,
              },
              classes.tableSubHead
            )}
            align={"center"}
          >
            Total
          </TableCell>
          {Object.keys(totalData.data).map((_key, index) => (
            <TableCell
              className={classNames(
                { [classes.borderRight]: highlightAltRow },
                _key === "ggr" && classes.grey,
                totalData?.cellStyleByColumn &&
                  totalData?.cellStyleByColumn[_key]
                  ? totalData?.cellStyleByColumn[_key]
                  : classes.tableSubHead
              )}
              align={
                columns?.find((info) => info?.id === _key)?.alignBody || "left"
              }
              key={index}
              style={{ fontWeight: 600 }}
            >
              {totalData?.data[_key] || "-"}
            </TableCell>
          ))}
        </TableRow>
      )}
    </TableHead>
  );
};

const Row = ({
  actions: Actions,
  actionHandlers,
  columns,
  data,
  rowId,
  onRowClick,
  cellWidthStyle,
  tableFieldValue,
  highlightAltRow,
}) => {
  const classes = useStyles();

  return (
    <TableRow
      hover
      onClick={() => onRowClick(data)}
      className={classNames(classes.borderRight, {
        [classes.grey]: highlightAltRow && rowId % 2 !== 0,
      })}
    >
      {columns.map((column, _index) => {
        if (column.id === "actions") {
          return (
            <TableCell
              key={column.id || `action-${_index}`}
              align={column?.alignAction || "left"}
              padding="checkbox"
              className={classNames(classes.bodyCell, {
                [classes.padding]: highlightAltRow,
              })}
            >
              <Actions
                rowId={rowId}
                data={data}
                actionHandlers={actionHandlers}
              />
            </TableCell>
          );
        } else if (column.id === "field") {
          return (
            <TableCell
              align={column?.alignBody || "center"}
              key={column.id || `body-${_index}`}
              className={classNames(
                { [classes.padding]: highlightAltRow },
                { [classes.borderRight]: highlightAltRow }
              )}
            >
              <SimpleTextInput
                id={column?.name}
                name={column?.name}
                value={
                  tableFieldValue?.[column?.name]?.[data[column?.mapId]] || ""
                }
                onChange={(e) =>
                  column?.handleChange(data, e.target.value, e.target.name)
                }
              />
            </TableCell>
          );
        } else if (column.id === "thumbnail") {
          return (
            <TableCell
              align={column?.alignBody || "center"}
              key={column.id || `body-${_index}`}
              className={classNames(
                data?.cellStyleByColumn && data?.cellStyleByColumn[column.id]
                  ? data?.cellStyleByColumn[column.id]
                  : classes.bodyCell,
                { [classes.borderRight]: highlightAltRow }
              )}
            >
              <Box
                className={classes.modalPreview}
                onClick={() => actionHandlers?.openModal(data)}
              >
                Preview
              </Box>
            </TableCell>
          );
        } else {
          return (
            <TableCell
              align={column?.alignBody || "left"}
              key={column.id || `body-${_index}`}
              className={classNames(
                data?.cellStyleByColumn && data?.cellStyleByColumn[column.id]
                  ? data?.cellStyleByColumn[column.id]
                  : classes.bodyCell,
                { [classes.padding]: highlightAltRow },
                { [classes.borderRight]: highlightAltRow }
              )}
            >
              <Box className={cellWidthStyle || classes.bodyCellContent}>
                {data[column.id] ?? "-"}
              </Box>
            </TableCell>
          );
        }
      })}
    </TableRow>
  );
};

Row.defaultProps = {
  onRowClick: () => {},
  onColumnClick: () => {},
  actions: () => <></>,
};

const Body = ({
  actions,
  actionHandlers,
  rows,
  columns,
  onRowClick,
  cellWidthStyle,
  tableFieldValue,
  highlightAltRow,
}) => {
  return (
    <TableBody>
      {rows?.length > 0 ? (
        rows.map((row, index) => (
          <Row
            key={row.id || index}
            actions={actions}
            actionHandlers={actionHandlers}
            columns={columns}
            data={row}
            rowId={index}
            onRowClick={onRowClick}
            cellWidthStyle={cellWidthStyle}
            tableFieldValue={tableFieldValue}
            highlightAltRow={highlightAltRow}
          />
        ))
      ) : (
        <TableRow>
          <TableCell align="center" colSpan={columns.length}>
            <Typography align="center">No data to show</Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

function Table(props) {
  const {
    columns,
    actions,
    actionHandlers = null,
    data,
    totalData = null,
    onRowClick,
    onColumnClick,
    currentPage,
    pageSize,
    totalEntries,
    onChangePage,
    onRowsPerPageChange,
    cellWidthStyle = null,
    hidePagination = false,
    highlightAltRow = false,
    tableFieldValue = {},
  } = props;

  return (
    <>
      {!hidePagination && (
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalEntries={totalEntries}
          onChangePage={onChangePage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
      <TableContainer
        style={{
          width: "100%",
          maxHeight: "74vh",
          overflow: "auto",
        }}
        elevation={0}
      >
        <MuiTable stickyHeader>
          <Head
            columns={props.columns}
            onColumnClick={onColumnClick}
            totalData={totalData}
            highlightAltRow={highlightAltRow}
          />
          <Body
            actions={actions}
            actionHandlers={actionHandlers}
            rows={data}
            columns={columns}
            onRowClick={onRowClick}
            cellWidthStyle={cellWidthStyle}
            tableFieldValue={tableFieldValue}
            highlightAltRow={highlightAltRow}
          />
        </MuiTable>
      </TableContainer>
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      numeric: PropTypes.bool,
    })
  ),
  actions: PropTypes.any,
  data: PropTypes.array,
  totalData: PropTypes.object,
  actionHandlers: PropTypes.any,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalEntries: PropTypes.number,
  onRowClick: PropTypes.func,
  onChangePage: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onColumnClick: PropTypes.func,
  cellWidthStyle: PropTypes.any,
  hidePagination: PropTypes.bool,
};

export default Table;
