/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContentShell from "components/ContentShell";
import SearchInput from "components/SearchInput";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { opacity2hex } from "utils/miscUtils";
import configData from "config/config.json";
import { getLeagues } from "services/leagueServices";
import { useSnackbar } from "hooks/useSnackbar";
import { getLeagueTableColumns } from "utils/tableHelper/leaguesTableHelper";
import usePaginationHook from "hooks/usePagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import sessionHelper from "services/sessionHelper";
import { PermittedRoles } from "utils/permissions";
import CustomButton from "components/CustomButton";

const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: theme.palette.common.offWhite + opacity2hex("0.80"),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  container: {
    padding: "12px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const RowActions = ({ rowId, data }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowAction = (data) => {
    setAnchorEl(null);
    navigate(configData.routes.leagues.edit?.replace(":id", data?._id));
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleRowAction(data)}>Edit</MenuItem>
        <MenuItem onClick={handleClose}>Delete</MenuItem>
      </Menu>
    </>
  );
};

const defaultPageSize = 10,
  defaultPageNo = 1;

const Leagues = () => {
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [columns, setColumns] = useState([]);
  const [listData, setListData] = useState([]);

  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.LEAGUE.UPDATE_ROLES
  );

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({
      totalItems: listData?.length,
      defaultPageNo,
      defaultPageSize,
    });

  const createLeague = () => navigate(configData.routes.leagues.create);

  const fetchData = async (_search) => {
    setLoading(true);
    try {
      // feth leagues data
      const resp = await getLeagues({ pageNo, pageSize, searchValue: _search });

      setListData(resp?.data?.items);
      showSnackbar(resp?.message, "success");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  const onSearch = (value) => {
    setSearchValue(value);
    onPageSizeChange({ target: { value: defaultPageSize } });
    fetchData(value);
  };

  useEffect(() => {
    const initialColumns = getLeagueTableColumns().filter(
      (col) => col.id !== "actions" || hasEditPermission
    );
    setColumns(initialColumns);

    fetchData();
  }, [pageNo, pageSize, hasEditPermission]);

  return (
    <ContentShell
      title="Leagues & Milestones"
      loading={loading}
      actions={
        <Box style={{ display: "flex", flexFlow: "row nowrap", gap: 4 }}>
          <SearchInput
            value={searchValue}
            placeholder="Search a league"
            onSearch={onSearch}
          />
          {sessionHelper.hasRole(PermittedRoles.LEAGUE.CREATE_ROLES) && (
            <CustomButton onClick={createLeague}>Create</CustomButton>
          )}
        </Box>
      }
    >
      <Box className={styles.container}>
        <Table
          columns={columns}
          data={listData}
          currentPage={pageNo}
          pageSize={pageSize}
          totalEntries={listData.length}
          highlightAltRow={true}
          actions={RowActions}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
        />
      </Box>
    </ContentShell>
  );
};

export default Leagues;
