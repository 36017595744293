import * as Yup from "yup";

// Pack Validation

const boosterProbabilitySchema = Yup.object().shape({
  boosterId: Yup.object().required(),
  probability: Yup.number()
    .positive()
    .required("Booster Probability is required")
    .min(1, "Booster Probability must be at least 1")
    .max(100, "Booster Probability must not exceed 100"),
});

const dropProbabilitySchema = Yup.object().shape({
  ovrMin: Yup.number().positive().min(1).max(100).required(),
  ovrMax: Yup.number().positive().min(1).max(100).required(),
  probability: Yup.number()
    .positive()
    .required("Drop Probability is required")
    .min(1, "Drop Probability must be at least 1")
    .max(100, "Drop Probability must not exceed 100"),
});

const slotSchema = Yup.object().shape({
  type: Yup.string(),
  amount: Yup.number().when("type", {
    is: "Currency",
    then: Yup.number()
      .positive()
      .required('Amount is required for type "Currency"')
      .min(1, "Amount must be at least 1"),
    otherwise: Yup.number().optional(),
  }),
  playerPool: Yup.array().when("type", {
    is: "PlayerPool",
    then: Yup.array()
      .of(Yup.mixed().required("Player is required for type 'PlayerPool'"))
      .min(1, "At least one player is required in 'PlayerPool'"),
    otherwise: Yup.array().optional(),
  }),
  playerCard: Yup.mixed().when("type", {
    is: "NamedPlayer",
    then: Yup.mixed().required('Player is required for type "NamedPlayer"'),
    otherwise: Yup.mixed().optional(),
  }),
  dropProbabilities: Yup.array().when("type", {
    is: "Player",
    then: Yup.array()
      .of(dropProbabilitySchema)
      .min(1, "At least one drop probability is required")
      .required('Drop probabilities are required for type "Player"')
      .test(
        "total-drop-probability",
        "Drop probability sum should be 100",
        function (value) {
          if (!value || value.length === 0) return true;
          const totalProbability = value.reduce(
            (sum, item) => sum + item.probability,
            0
          );
          return totalProbability === 100;
        }
      ),
    otherwise: Yup.array().optional(),
  }),
  boosterProbabilities: Yup.array().when("type", {
    is: "Booster",
    then: Yup.array()
      .of(boosterProbabilitySchema)
      .min(1, "At least one booster probability is required")
      .required('Booster probabilities are required for type "Booster"')
      .test(
        "total-booster-probability",
        "Booster probability sum should be 100",
        function (value) {
          if (!value || value.length === 0) return true;
          const totalProbability = value.reduce(
            (sum, item) => sum + item.probability,
            0
          );
          return totalProbability === 100;
        }
      ),
    otherwise: Yup.array().optional(),
  }),
});

export const packSchema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.array().of(Yup.string()).required(),
  summary: Yup.string().required(),
  isDisabled: Yup.boolean().required(),
  price: Yup.number().required(),
  slots: Yup.array()
    .of(
      slotSchema.test({
        name: "custom-slot-error",
        test: (value, context) => {
          if (!value || !value.type) {
            const index = Number(context.path.split("[")[1].split("]")[0]);
            return context.createError({
              message: `Slot ${index + 1}\'s type is required`,
            });
          }
          return true;
        },
      })
    )
    .required(),
  isFTUPack: Yup.boolean().required(),
  packPredefinedImageType: Yup.string().optional(),
  fileType: Yup.string().when("packPredefinedImageType", {
    is: (value) => !value,
    then: Yup.string().required(),
    otherwise: Yup.string().optional(),
  }),
});

// League Validation
const milestoneSchema = Yup.object().shape({
  number: Yup.number().positive().required("Milestone 'Number' is required."),
  steps: Yup.number().positive().required("Milestone 'steps' is required."),
  rewardPack: Yup.object().nullable().required("'Reward pack' is required."),
  rewardCoins: Yup.number().positive().required("'Milestone rewardCoin' is required."),
});

export const leagueSchema = Yup.object().shape({
  name: Yup.string().required(),
  number: Yup.number().positive().required(),
  k1: Yup.number().positive().required(),
  k2: Yup.number().positive().required(),
  milestones: Yup.array().of(milestoneSchema).min(1),
});
