import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles, styled } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import configData from "config/config.json";
import { getVersion } from "utils/miscUtils";
import sessionHelper from "services/sessionHelper"; // Ensure sessionHelper is imported
import { Typography } from "@mui/material";
import { userRoles } from "utils/constants";

const drawerWidth = 200;
const mobileDrawerWith = "100%";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    borderRight: "none",
    backgroundColor: `${theme.palette.secondary.main} !important`,
    width: drawerWidth,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      width: mobileDrawerWith,
      zIndex: 999,
    },
  },
  closeIcon: {
    top: 0,
    right: 0,
    height: 36,
    width: 36,
    color: theme.palette.common.white,
  },
  link: {
    textAlign: "left",
    fontWeight: 400,
    color: theme.palette.secondary.label,
    textDecoration: "none",
    cursor: "pointer",
    "& span": {
      fontSize: 13,
    },
  },
  selectedCell: {
    backgroundColor: "#3F3350",
  },
  selectedCellText: {
    color: `${theme.palette.common.white} !important`,
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const IconMap = {
  reports: <FolderOpenIcon style={{ color: "#EEEEEEB3", fontSize: 24 }} />,
  platform: <FolderOpenIcon style={{ color: "#EEEEEEB3", fontSize: 24 }} />,
  api: <FolderOpenIcon style={{ color: "#EEEEEEB3", fontSize: 24 }} />,
  role: <FolderOpenIcon style={{ color: "#EEEEEEB3", fontSize: 24 }} />,
  brand: <FolderOpenIcon style={{ color: "#EEEEEEB3", fontSize: 24 }} />,
  user: <PersonOutlineIcon style={{ color: "#EEEEEEB3", fontSize: 24 }} />,
};

const ExpandButton = ({ curr, selected }) => {
  const classes = useStyles();
  return curr === selected ? (
    <ExpandLess style={{ color: "white" }} className={classes.link} />
  ) : (
    <ExpandMore className={classes.link} />
  );
};

const Sidebar = ({ open, onToggleOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [selectedSubmenu, setSelectedSubmenuMenu] = useState(0);
  const [list, setList] = useState({});

  const userRole = sessionHelper.role; // Get the user's role

  const handleChange = (link, index) => {
    setSelectedMenu(selectedMenu === index ? null : index);
    setSelectedSubmenuMenu(null);
  };

  const handleClick = (url, index) => {
    setSelectedSubmenuMenu(index);
    navigate(url);
  };

  const [version, setVersion] = useState("");

  React.useEffect(() => {
    const _list = {};

    // Filter list items by role and hide empty folders
    for (const link of Object.keys(configData.sideBarLinks)) {
      const menu = configData.sideBarLinks[link].menu.filter(
        (item) =>
          item.requiredRole.length === 0 || // No restriction
          item.requiredRole.includes(userRole) || // Matches user role
          userRole === userRoles.SUPER_ADMIN // Allow SUPER_ADMIN
      );

      // Only add to _list if there are accessible menu items
      if (menu.length > 0) {
        _list[link] = {
          ...configData.sideBarLinks[link],
          menu: menu,
        };
      }
    }

    setList(_list);

    const url = location.pathname.split("/");
    const sideBarKeys = Object.keys(_list);
    sideBarKeys.forEach((item) => {
      let subMenu = _list[item]?.menu.find((el) =>
        el.url.includes(url[url?.length - 1])
      );
      let index = _list[item]?.menu.indexOf(subMenu);

      if (index > -1) {
        setSelectedMenu(sideBarKeys.indexOf(item));
        setSelectedSubmenuMenu(index);
      }
    });

    getVersion().then(setVersion);
  }, [userRole, location.pathname]);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      classes={{ paper: classes.drawerPaper }}
    >
      <Hidden smUp>
        <DrawerHeader>
          <IconButton onClick={onToggleOpen} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
      </Hidden>

      <List>
        <ListItemButton>
          <ListItemIcon style={{ minWidth: "0" }}></ListItemIcon>
          <ListItemText className={classes.link} primary={"MENU"} />

          {version ? (
            <Typography style={{ color: "white" }}>{version}</Typography>
          ) : (
            ""
          )}
        </ListItemButton>
        {Object.keys(list).map((link, index) => (
          <Box key={list[link].title}>
            <ListItemButton onClick={() => handleChange(link, index)}>
              <ListItemIcon style={{ minWidth: "32px" }}>
                {IconMap[link] || (
                  <FolderOpenIcon
                    style={{ color: "#EEEEEEB3", fontSize: 24 }}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                className={classNames(classes.link, {
                  [classes.selectedCellText]: index === selectedMenu,
                })}
                primary={list[link].title}
              />

              <ExpandButton curr={index} selected={selectedMenu} />
            </ListItemButton>
            <Collapse in={index === selectedMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {list[link].menu.map((menuItem, subIndex) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={menuItem.title}
                    onClick={() => handleClick(menuItem.url, subIndex)}
                    className={classNames({
                      [classes.selectedCell]: subIndex === selectedSubmenu,
                    })}
                  >
                    <ListItemIcon style={{ minWidth: "20px" }} />
                    <ListItemText
                      className={classNames(classes.link, {
                        [classes.selectedCellText]:
                          subIndex === selectedSubmenu,
                      })}
                      primary={menuItem.title}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  onToggleOpen: PropTypes.func,
};

Sidebar.defaultProps = {
  open: true,
};

export default Sidebar;
export { drawerWidth };
