import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    gap: 30,
    marginRight: 30,
    // [theme.breakpoints.down("sm")]: {
    // backgroundColor: theme.palette.primary.main,
    // height: 300,
    // },
  },
  buttonContainer: { display: "flex", flexFlow: "column wrap", gap: 10 },
  previewBox: {
    aspectRatio: "16/9",
    height: "220px",
    objectFit: "contain",
    marginTop: 2,
    [theme.breakpoints.down("sm")]: {},
  },
  reduceHeight: {
    // height: 200,
  },
}));

const defaultMessage = "No preview available";

const FilePicker = ({ value, onFileSelect, name }) => {
  const styles = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState();
  const [fileSupportMessage, setFileSupportMessage] = useState(defaultMessage);

  const onChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setSelectedFile(file);

    if (onFileSelect) {
      onFileSelect(file);
    }

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
      setFileSupportMessage("Unsupported File");
    }

    event.target.value = null;
  };

  const removeImage = () => {
    setFileSupportMessage(defaultMessage);
    setSelectedFile(null);
    setPreview(null);
    if (onFileSelect) {
      onFileSelect(null);
    }
  };

  return (
    <Box
      className={classNames(styles.container, {
        [styles.reduceHeight]: value,
      })}
    >
      <Box>
        <Typography variant="body2">
          {value?.imageUrl
            ? `Selected Image: ${value.label}`
            : selectedFile?.name
            ? `Selected File: ` + selectedFile?.name
            : "No File Selected"}
        </Typography>
        <input
          type="file"
          id={name}
          accept="image/png"
          style={{ display: "none" }}
          onChange={onChange}
        />
        <Box className={styles.buttonContainer}>
          <label htmlFor={name}>
            <Button
              sx={{
                width: 140,
              }}
              size="small"
              variant="contained"
              component="span"
              startIcon={<UploadFileIcon />}
            >
              Choose File
            </Button>
          </label>
          {selectedFile?.name && (
            <Button
              sx={{
                width: 140,
              }}
              size="small"
              variant="contained"
              component="span"
              startIcon={<DeleteIcon />}
              onClick={removeImage}
            >
              Remove
            </Button>
          )}
        </Box>
      </Box>

      {(selectedFile || value?.imageUrl) && (
        <>
          {preview || value?.imageUrl ? (
            <Box
              component="img"
              src={value?.imageUrl || preview}
              alt="Preview"
              className={styles.previewBox}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              {fileSupportMessage}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default FilePicker;
