export const getBannerTableColums = (params) => {
  return [
    {
      id: "title",
      label: `Title`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "navigationPath",
      label: `Navigation Path`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "body",
      label: `Body`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "isActive",
      label: `Status`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    //{ id: "thumbnail", label: "Preview" },
    { id: "actions", label: "Actions" },
  ];
};
