import * as React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import { signOut } from "services/authService";
import configData from "config/config.json";
import AppLogo from "./AppLogo";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.gradient,
    padding: "8px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
  icon: {
    fontSize: 40,
    color: "#fff",
  },
  hamburger: {
    marginRight: 8,
    color: "#fff",
  },
  mobileMenu: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    flexFlow: "column nowrap",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  }
}));

function NavBar({ onToggleOpen, actions = null }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (event = "") => {
    switch (event) {
      case "logout":
        signOut();
        navigate(configData.routes.signIn);
        break;
      default:
    }
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [userInfo, setUserInfo] = React.useState(null);

  React.useEffect(() => {
    const _data = localStorage.getItem("userInfo");
    setUserInfo(JSON.parse(_data));
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disableTouchRipple>{userInfo?.role}</MenuItem>
      <MenuItem disableTouchRipple>{userInfo?.email}</MenuItem>
      <MenuItem onClick={() => handleMenuClose("logout")}>Logout</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large" color="inherit">
          <AccountCircle className={classes.icon} />
        </IconButton>
      </MenuItem> */}
      <MenuItem divider={true} disableTouchRipple>
        <Box className={classes.mobileMenu}>
          <span>{userInfo?.role}</span>
          <span style={{fontSize: 14}}>{userInfo?.email}</span>
        </Box>
      </MenuItem>
      {/* <MenuItem disableTouchRipple>{userInfo?.email}</MenuItem> */}
      <MenuItem onClick={() => handleMenuClose("logout")}>Logout</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className={classes.root}>
          <IconButton
            id="hamburgerMenu"
            size="large"
            edge="end"
            onClick={onToggleOpen}
            className={classes.hamburger}
            // onMouseEnter={() => setTimeout(() => onToggleOpen(true), 100)}
            // onMouseLeave={() => setTimeout(() => onToggleOpen(false), 100)}
          >
            <MenuIcon />
          </IconButton>
          <AppLogo />
          {actions}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton size="large" edge="end" onClick={handleProfileMenuOpen}>
              <AccountCircle className={classes.icon} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

export default NavBar;
