import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { defaultPageSize, defaultPageNo } from "utils/constants";
import { updateQueryParams } from "utils/queryParamsHelper";

const useSearchHook = (defaultSearch = "") => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearch = searchParams.get("search") || defaultSearch;
  const [searchValue, setSearchValue] = useState(initialSearch);

  const onSearch = (value) => {
    setSearchValue(value);
    updateQueryParams({ search: value }, setSearchParams);
  };

  return {
    searchValue,
    onSearch,
  };
};

export default useSearchHook;
