import { useState } from "react";

const useOpenClose = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [extraData, setExtraData] = useState({});

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const updateExtra = (data) => setExtraData((st) => ({ ...st, ...data }));

  return {
    isOpen,
    extraData,
    open,
    close,
    updateExtra,
  };
};

export default useOpenClose;
