/* eslint-disable react-hooks/exhaustive-deps */
import ContentShell from "components/ContentShell";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import configData from "config/config.json";
import { useSnackbar } from "hooks/useSnackbar";
import useStyles from "styles/brand/brandEditor";
import { SimpleTextInput } from "components/FormTextInput";
import {
  createCurrency,
  getCurrencyInfo,
  updateCurrency,
} from "services/currencyService";
import * as Yup from "yup";

const _currencyDetails = {
  currencyId: 0,
  isoCode: "",
  isoNumericCode: 356,
  isoName: "",
  minBet: 0,
  maxBet: 0,
  maxPayOut: 0,
  gbpExchangeRate: 0,
};

const currencySchema = Yup.object().shape({
  currencyId: Yup.number().required("Currency Id is required"),
  isoCode: Yup.string().length(3).required("ISO Code is required"),
  isoNumericCode: Yup.number()
    .positive()
    .required("ISO Numeric Code is required"),
  isoName: Yup.string().required("ISO name is required"),
  minBet: Yup.number().positive().required("Minimun Bet is Required"),
  maxBet: Yup.number().positive().required("Maximum Bet is Required"),
  maxPayOut: Yup.number().positive().required("Maximum Payout is Required"),
  gbpExchangeRate: Yup.number()
    .positive()
    .required("GBP Exchange Rate is Required"),
});

const CurrencyEditor = () => {
  const styles = useStyles();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const id = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [currencyDetails, setCurrencyDetails] = useState(_currencyDetails);

  const cancel = () => navigate(configData.routes.currency.currency);

  const submit = async () => {
    try {
      setLoading(true);
      await currencySchema.validate(currencyDetails);
      let response;
      if (id === "create") {
        response = await createCurrency(currencyDetails);
      } else {
        const { currencyId, ...rest } = currencyDetails;
        response = await updateCurrency(rest, id);
      }

      showSnackbar(response.message, "success");
      setLoading(false);
      navigate(configData.routes.currency.currency);
    } catch (error) {
      if (error?.inner)
        error.inner.forEach((_err) => showSnackbar(_err.message, "error"));
      showSnackbar(error.message, "error");
      setLoading(false);
    }
  };

  const onChange = async (e) => {
    const { name, value } = e?.target;

    setCurrencyDetails((state) => ({ ...state, [name]: value }));
  };

  const fetchCurrencyInfo = async () => {
    try {
      setLoading(true);
      const resp = await getCurrencyInfo(id);
      if (resp && resp?.data) {
        const { _id, id, updatedAt, createdAt, ...rest } = resp.data;
        setCurrencyDetails(rest);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  useEffect(() => {
    id !== "create" && fetchCurrencyInfo();
  }, []);

  return (
    <ContentShell
      showBackButton
      onBackButtonClick={cancel}
      title={`${id === "create" ? "Create" : "Edit"} Currency`}
      loading={loading}
      actions={
        <Box className={styles.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={cancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={submit}>
            Save
          </Button>
        </Box>
      }
    >
      <Box className={styles.container}>
        <Box className={styles.firstFormContainer}>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Name (Eg. Indian Rupee, US Dollar)"
              name="isoName"
              value={currencyDetails.isoName}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Curency ID"
              name="currencyId"
              value={currencyDetails.currencyId}
              onChange={onChange}
              disabled={id !== "create"}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="ISO Code (Eg. INR, USD)"
              name="isoCode"
              value={currencyDetails.isoCode}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="ISO Numeric Code (Eg. 356, 840)"
              name="isoNumericCode"
              value={currencyDetails.isoNumericCode}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Minimum Bet"
              name="minBet"
              value={currencyDetails.minBet}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Maximum Bet"
              name="maxBet"
              value={currencyDetails.maxBet}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="Maximum PayOut"
              name="maxPayOut"
              value={currencyDetails.maxPayOut}
              onChange={onChange}
            />
          </Box>
          <Box className={styles.field}>
            <SimpleTextInput
              label="GBP exchange rate"
              name="gbpExchangeRate"
              value={currencyDetails.gbpExchangeRate}
              onChange={onChange}
            />
          </Box>
        </Box>
      </Box>
    </ContentShell>
  );
};

export default CurrencyEditor;
