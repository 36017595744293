import { lightGreen, red } from "@mui/material/colors";

const theme = {
  palette: {
    common: {
      white: "#fff",
      offWhite: "#1D1030",
      black: "#000",
      grey: "#cccccc",
      red: "#ce181e",
      green: "#2db300",
      lightGrey: "#d3d3d3"
    },
    gradient: "linear-gradient(to right, #3E324F 0%, #3F3350 100%)",
    primary: {
      main: "#1D1030",
    },
    secondary: {
      light: "#3F3350",
      main: "#1D1030",
      label: "#EEEEEEB3",
    },
    success: {
      light: lightGreen[100],
      main: lightGreen[500],
      dark: lightGreen[900],
    },
    danger: {
      light: red[100],
      main: red[500],
      dark: red[900],
    },
    button: {
      backgrounColor: "#ce181e",
      secondaryTextColor: "#505d69",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    fontSize: 14,
  },
  typography2: {
    h1: {
      fontFamily: ["Open Sans", "sans-serif"].join(","),
      fontSize: 14,
    },
    h2: {
      fontFamily: ["Open Sans", "sans-serif"].join(","),
      fontSize: 16,
    },
    h3: {
      fontFamily: ["Open Sans", "sans-serif"].join(","),
      fontSize: 18,
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        marginTop: 1,
      },
    },
    MuiButton: {
      root: {
        fontWeight: "bold",
      },
    },
  },
};

export default theme;
