import { Client } from "./_clientService";

async function getCurrencies({
  pageNo,
  pageSize,
  searchValue,
  skipPagination,
  sortBy = "currencyId",
  sortDir = 1,
}) {
  let path = `/currency/list?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDir}`;

  if (searchValue) path += `&search=${searchValue}`;
  if (skipPagination) path += `&skipPagination=true`;

  const resp = await new Client({ path }).get();

  return resp;
}

async function createCurrency(payload) {
  const resp = await new Client({
    path: `/currency/create`,
    payload,
  }).post();

  return resp;
}

async function getCurrencyInfo(id) {
  let path = `/currency/info/${id}`;
  const resp = await new Client({ path }).get();

  return resp;
}

async function updateCurrency(payload, id) {
  const resp = await new Client({
    path: `/currency/update/${id}`,
    payload,
  }).patch();

  return resp;
}

export { getCurrencies, createCurrency, updateCurrency, getCurrencyInfo };
