export function getTFGPlayerTableColumns(filterOption) {
  return [
    {
      id: "playerId",
      label: `Player Id`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    // {
    //   id: "fullName",
    //   label: `Full Name`,
    //   type: "string",
    //   isSortable: true,
    //   alignHead: "center",
    //   alignBody: "center",
    // },
    {
      id: "displayName",
      label: `Display Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "commonNameForename",
      label: `First name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "commonNameSurname",
      label: `Surname`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "league",
      label: `League`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "club",
      label: `Club`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    // {
    //   id: "clubShortCode",
    //   label: `Club ABBR.`,
    //   type: "string",
    //   isSortable: true,
    //   alignHead: "center",
    //   alignBody: "center",
    // },
    {
      id: "preferredPosition",
      label: `Primary Pos`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "secondaryPosition1",
      label: `2nd pos`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "secondaryPosition2",
      label: `3rd pos`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "nationality",
      label: `Nationality`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "attackRating",
      label: `ATT. Rating`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "defenseRating",
      label: `DEF. Rating`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "creativityRating",
      label: `CRT. Rating`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "overallRating",
      label: `OVRL. Rating`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    // {
    //   id: "rarity",
    //   label: `Rarity`,
    //   type: "string",
    //   isSortable: true,
    //   alignHead: "center",
    //   alignBody: "center",
    // },
    {
      id: "shirtNumber",
      label: `Shirt No.`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "actions",
      label: `Actions`,
    },
  ];
}

// {
//     "_id": "676918ab103741660cb3386b",
//     "cardId": null,
//     "playerId": "bce4c7b3-5ec8-4bef-8a4d-10ab85498553",
//     "fullName": "Viola Benito",
//     "commonName": "Nicolas Viola",
//     "displayName": "Viola",
//     "displayNameAbb": null,
//     "club": "Cagliari",
//     "clubShortCode": "CAG",
//     "league": "Serie A (Italy)",
//     "preferredPosition": "CM",
//     "secondaryPosition1": "CDM",
//     "secondaryPosition2": "CAM",
//     "nationality": "Italy",
//     "nationalityImageUrl": "https://pbf-dev.s3.amazonaws.com/countries/Italy.svg",
//     "attackRating": 78,
//     "defenseRating": 56,
//     "creativityRating": 57,
//     "overallRating": 63,
//     "deactivated": null,
//     "sentDate": null,
//     "defaultAvatarUrl": null,
//     "rarity": "bronze",
//     "shirtNumber": 10,
//     "imageUrl": "https://pbf-dev.s3.amazonaws.com/playerJerseys-clubId/@1x_webp/Men/d3510248-e90d-4427-993b-b147d7813f08/10.webp"
// },
