import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AppShell from "components/AppShell";
import configData from "config/config.json";
import PlayerCardEditor from "./pages/admin/PlayerCardEditor";
import EditClub from "./pages/admin/ClubEditor";
import EditPosition from "./pages/admin/PositionEditor";
import PackList from "./pages/packs/PackList";
import PlayerCards from "./pages/admin/PlayerCardsList";
import ClubsList from "./pages/admin/ClubsList";
import PositionsList from "./pages/admin/PositionsList";
import Leagues from "./pages/leagues/Leagues";
import LeagueEditor from "./pages/leagues/LeagueEditor";
import PackEditor from "./pages/packs/PackEditor";
import { PrivateRoute } from "./routes";
import AccessDenied from "components/AccessDenied";
import Dashboard from "components/Dashboard";
import { PermittedRoles } from "utils/permissions";
import Report from "components/Report";
import PageNotFound from "components/NotFound";
import Platforms from "./pages/platform/Platforms";
import PlatformEditor from "./pages/platform/PlatformEditor";
import Operators from "./pages/operator/Operators";
import OperatorEditor from "./pages/operator/OperatorEditor";
import BrandEditor from "./pages/brand/BrandEditor";
import Brands from "./pages/brand/Brands";
import Currency from "./pages/currency/Currency";
import CurrencyEditor from "./pages/currency/CurrencyEditor";
import PBFPlayers from "./pages/players/PBFPlayerList";
import TFGPlayers from "./pages/players/TFGPlayerList";
import TFGPlayerUpdate from "./pages/players/TFGPlayerUpdate";
import Banner from "./pages/banners/Banner";
import BannerEditor from "./pages/banners/BannerEditor";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname === "/") {
      navigate(configData.routes.dashboard);
    }
  }, [location, navigate]);

  return (
    <AppShell>
      <Routes location={location}>
        <Route
          path={configData.routes.players.PBFPlayers}
          element={
            <PrivateRoute
              requiredRole={PermittedRoles.PLAYERS.PBF_PLAYERS_LIST}
            >
              <PBFPlayers />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.players.TFGPlayers}
          element={
            <PrivateRoute
              requiredRole={PermittedRoles.PLAYERS.TFG_PLAYERS_LIST}
            >
              <TFGPlayers />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.players.TFGPlayerUpdate}
          element={
            <PrivateRoute
              requiredRole={PermittedRoles.PLAYERS.TFG_PLAYER_UPDATE}
            >
              <TFGPlayerUpdate />
            </PrivateRoute>
          }
        />
        {/* Positions routes */}
        <Route
          path={configData.routes.playerCard.positions}
          element={
            <PrivateRoute requiredRole={PermittedRoles.POSITION.LIST_ROLES}>
              <PositionsList />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.playerCard.createPosition}
          element={
            <PrivateRoute requiredRole={PermittedRoles.POSITION.CREATE_ROLES}>
              <EditPosition />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.playerCard.editPosition}
          element={
            <PrivateRoute requiredRole={PermittedRoles.POSITION.UPDATE_ROLES}>
              <EditPosition />
            </PrivateRoute>
          }
        />

        {/* Clubs routes */}
        <Route
          path={configData.routes.playerCard.clubs}
          element={
            <PrivateRoute requiredRole={PermittedRoles.CLUB.LIST_ROLES}>
              <ClubsList />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.playerCard.createClub}
          element={
            <PrivateRoute requiredRole={PermittedRoles.CLUB.CREATE_ROLES}>
              <EditClub />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.playerCard.editClub}
          element={
            <PrivateRoute requiredRole={PermittedRoles.CLUB.UPDATE_ROLES}>
              <EditClub />
            </PrivateRoute>
          }
        />

        {/* PlayerCard routes */}
        <Route
          path={configData.routes.playerCard.playerCards}
          element={
            <PrivateRoute requiredRole={PermittedRoles.PLAYER.CARD.LIST_ROLES}>
              <PlayerCards />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.playerCard.createPlayerCard}
          element={
            <PrivateRoute
              requiredRole={PermittedRoles.PLAYER.CARD.CREATE_ROLES}
            >
              <PlayerCardEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.playerCard.editPlayerCard}
          element={
            <PrivateRoute
              requiredRole={PermittedRoles.PLAYER.CARD.UPDATE_ROLES}
            >
              <PlayerCardEditor />
            </PrivateRoute>
          }
        />

        {/* Packs routes */}
        <Route
          path={configData.routes.packs.packs}
          element={
            <PrivateRoute requiredRole={PermittedRoles.PLAYER.PACK.LIST_ROLES}>
              <PackList />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.packs.create}
          element={
            <PrivateRoute
              requiredRole={PermittedRoles.PLAYER.PACK.CREATE_ROLES}
            >
              <PackEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.packs.edit}
          element={
            <PrivateRoute
              requiredRole={PermittedRoles.PLAYER.PACK.UPDATE_ROLES}
            >
              <PackEditor />
            </PrivateRoute>
          }
        />

        {/* Leagues routes */}
        <Route
          path={configData.routes.leagues.leagues}
          element={
            <PrivateRoute requiredRole={PermittedRoles.LEAGUE.LIST_ROLES}>
              <Leagues />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.leagues.create}
          element={
            <PrivateRoute requiredRole={PermittedRoles.LEAGUE.CREATE_ROLES}>
              <LeagueEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.leagues.edit}
          element={
            <PrivateRoute requiredRole={PermittedRoles.LEAGUE.UPDATE_ROLES}>
              <LeagueEditor />
            </PrivateRoute>
          }
        />

        {/* Platforms routes */}
        <Route
          path={configData.routes.platforms.platforms}
          element={
            <PrivateRoute requiredRole={PermittedRoles.PLATFORM.LIST_ROLES}>
              <Platforms />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.platforms.create}
          element={
            <PrivateRoute requiredRole={PermittedRoles.PLATFORM.CREATE_ROLES}>
              <PlatformEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.platforms.edit}
          element={
            <PrivateRoute requiredRole={PermittedRoles.PLATFORM.UPDATE_ROLES}>
              <PlatformEditor />
            </PrivateRoute>
          }
        />

        {/* Operators routes */}
        <Route
          path={configData.routes.operators.operators}
          element={
            <PrivateRoute requiredRole={PermittedRoles.OPERATOR.LIST_ROLES}>
              <Operators />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.operators.create}
          element={
            <PrivateRoute requiredRole={PermittedRoles.OPERATOR.CREATE_ROLES}>
              <OperatorEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.operators.edit}
          element={
            <PrivateRoute requiredRole={PermittedRoles.OPERATOR.UPDATE_ROLES}>
              <OperatorEditor />
            </PrivateRoute>
          }
        />

        {/* Brands routes */}
        <Route
          path={configData.routes.brands.brands}
          element={
            <PrivateRoute requiredRole={PermittedRoles.BRAND.LIST_ROLES}>
              <Brands />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.brands.create}
          element={
            <PrivateRoute requiredRole={PermittedRoles.BRAND.CREATE_ROLES}>
              <BrandEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.brands.edit}
          element={
            <PrivateRoute requiredRole={PermittedRoles.BRAND.UPDATE_ROLES}>
              <BrandEditor />
            </PrivateRoute>
          }
        />

        {/* Reports routes */}
        <Route
          path={configData.routes.report}
          element={
            <PrivateRoute requiredRole={PermittedRoles.REPORT.INFO_ROLES}>
              <Report />
            </PrivateRoute>
          }
        />

        <Route
          path={configData.routes.dashboard}
          element={
            <PrivateRoute requiredRole={PermittedRoles.DASHBOARD.INFO_ROLES}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* currency routes */}
        <Route
          path={configData.routes.currency.currency}
          element={
            <PrivateRoute requiredRole={PermittedRoles.CURRENCY.LIST_ROLES}>
              <Currency />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.currency.create}
          element={
            <PrivateRoute requiredRole={PermittedRoles.CURRENCY.CREATE_ROLES}>
              <CurrencyEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.currency.edit}
          element={
            <PrivateRoute requiredRole={PermittedRoles.CURRENCY.UPDATE_ROLES}>
              <CurrencyEditor />
            </PrivateRoute>
          }
        />

        {/* Banners routes */}
        <Route
          path={configData.routes.banners.banners}
          element={
            <PrivateRoute requiredRole={PermittedRoles.BANNERS.LIST_ROLES}>
              <Banner />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.banners.create}
          element={
            <PrivateRoute requiredRole={PermittedRoles.BANNERS.CREATE_ROLES}>
              <BannerEditor />
            </PrivateRoute>
          }
        />
        <Route
          path={configData.routes.banners.edit}
          element={
            <PrivateRoute requiredRole={PermittedRoles.BANNERS.UPDATE_ROLES}>
              <BannerEditor />
            </PrivateRoute>
          }
        />

        <Route
          path={configData.routes.accessDenied}
          element={<AccessDenied />}
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AppShell>
  );
};

export default Home;
