/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Table from "components/Table";
import { packListTableColumn } from "utils/tableHelper/packListTableHelper";
import ContentShell from "components/ContentShell";
import { Button, Box, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { getPlayerPacks } from "services/packServices";
import { useSnackbar } from "hooks/useSnackbar";
import usePaginationHook from "hooks/usePagination";
import { opacity2hex } from "utils/miscUtils";
import SearchInput from "components/SearchInput";
import configData from "config/config.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import sessionHelper from "services/sessionHelper";
import { PermittedRoles } from "utils/permissions";
import CustomButton from "components/CustomButton";

const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: theme.palette.common.offWhite + opacity2hex("0.80"),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  playerCardParent: {
    padding: "12px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const RowActions = ({ rowId, data }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditClick = (data) => {
    setAnchorEl(null);
    navigate(configData.routes.packs.edit?.replace(":id", data?._id));
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleEditClick(data)}>Edit</MenuItem>
        <MenuItem onClick={handleClose}>
          {data.isDisabled ? "Enable" : "Disable"}
        </MenuItem>
      </Menu>
    </>
  );
};

const defaultPageSize = 10,
  defaultPageNo = 1;

const PackList = () => {
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.PLAYER.PACK.UPDATE_ROLES
  );

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({ defaultPageSize, defaultPageNo });

  const fetchData = async (v) => {
    setLoading(true);
    try {
      const resp = await getPlayerPacks({
        pageNo,
        pageSize: pageSize,
        search: v,
      });
      showSnackbar(resp.message, "success");
      setFilteredList(resp?.items);
      setLoading(false);
    } catch (error) {
      showSnackbar(error.message);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setSearchValue(value);
    onPageSizeChange({ target: { value: defaultPageSize } });
    fetchData(value);
  };

  const createPack = () => navigate(configData.routes.packs.create);

  useEffect(() => {
    const initialColumns = packListTableColumn().filter(
      (col) => col.id !== "actions" || hasEditPermission
    );
    setColumns(initialColumns);

    fetchData(searchValue);
  }, [pageNo, pageSize, hasEditPermission]);

  return (
    <ContentShell
      title="Player Pack List"
      loading={loading}
      actions={
        <Box style={{ display: "flex", flexFlow: "row nowrap", gap: 4 }}>
          <SearchInput
            value={searchValue}
            placeholder="Search a pack"
            onSearch={onSearch}
          />
          {sessionHelper.hasRole(PermittedRoles.PLAYER.PACK.CREATE_ROLES) && (
            <CustomButton onClick={createPack}>Create</CustomButton>
          )}
        </Box>
      }
    >
      <Box className={styles.playerCardParent}>
        <Table
          columns={columns}
          data={filteredList}
          currentPage={pageNo}
          actions={RowActions}
          pageSize={pageSize}
          totalEntries={filteredList.length}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
          highlightAltRow={true}
        />
      </Box>
    </ContentShell>
  );
};

export default PackList;
