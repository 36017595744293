export const platform = "65841448b4d5c2c9b6c89e0f";
export const operator = "658414bd15aca9d59aa36fd4";
export const brand = "6584156ea9ff07043a0efc82";
export const defaultCurrencyRate = "USD";

export const defaultCardDetails = {
  fullName: "Peter Horns",
  commonName: "P. Horns",
  displayName: "Peter Horns",
  displayNameAbb: "Horns",
  overallRating: "43",
  positionName:"",
  position: "",
  cardType: "Bronze",
  clubTextColor: "#DD0000",
  clubBGColor: "#0000FF",
  clubName: "",
  clubAbbrName: "",
  clubShortCode: "",
  nationality: "France",
  attackRating: 50,
  defenceRating: 50,
  creativityRating: 50,
  playerFaceImage: null,
  face: "Face 1",
  emblem: "Emblem 1",
  textColor: "#000000",
  positionBGColor: "#FF5733",
  imageUrl: "https://pbf-dev.s3.amazonaws.com/playerJerseys/Men/CGL/10.png",
  nationalityImageUrl: "https://pbf-dev.s3.amazonaws.com/countries/Japan.svg",
  nationality: "Japan"
};

export const positionColorValues = {
  GK: { positionBGColor: "#04DBFA", textColor: "#000000" },
  LB: { positionBGColor: "#04DBFA", textColor: "#000000" },
  CB: { positionBGColor: "#04DBFA", textColor: "#000000" },
  RB: { positionBGColor: "#04DBFA", textColor: "#000000" },
  LM: { positionBGColor: "#FFFF00", textColor: "#000000" },
  CM: { positionBGColor: "#FFFF00", textColor: "#000000" },
  RM: { positionBGColor: "#FFFF00", textColor: "#000000" },
  ST: { positionBGColor: "#FFA500", textColor: "#FFFFFF" },
};

export const BoosterSlotOptions = [
  {
    id: "Boost_All_05_5",
    label: "Boost_All_05_5",
  },
  {
    id: "Boost_Cre_05_3",
    label: "Boost_Cre_05_3",
  },
  {
    id: "Boost_Def_05_3",
    label: "Boost_Def_05_3",
  },
  {
    id: "Boost_Atk_05_3",
    label: "Boost_Atk_05_3",
  },
];

export const RoundStatus = [
  { label: "All", id: "ALL" },
  { label: "DEBIT_COMPLETED", id: "debit_completed" },
  { label: "CREDIT_COMPLETED", id: "credit_completed" },
  { label: "REFUND_COMPLETED", id: "refund_completed" },
  { label: "DEBIT_FAILED", id: "debit_failed" },
  { label: "CREDIT_FAILED", id: "credit_failed" },
  { label: "REFUND_FAILED", id: "refund_failed" },
];

export const CurrencyOptions = [
  { label: "INR", id: "INR" },
  { label: "EUR", id: "EUR" },
  { label: "USD", id: "USD" },
];

export const roles = [
  { id: "SUPER_ADMIN", label: "SUPER_ADMIN" },
  { id: "SUB_ADMIN", label: "SUB_ADMIN" },
  { id: "MANAGER", label: "MANAGER" },
  { id: "SUPPORT", label: "SUPPORT" },
];

export const PBO_STR = ["platform", "operator", "brand"];
export const DEFAULT_VARIANT = "inline";

export const GAME_TYPES = [
  { label: "Provably Games", id: "pg" },
  { label: "Slot Games", id: "sg" },
  { label: "Table game", id: "tg" },
];

export const defaultFontFamily = "Open Sans, sans-serif";

export const editPlayerPositions = [
  { value: "Attack", label: "Attack" },
  { value: "Midfield", label: "Midfield" },
  { value: "Defence", label: "Defence" },
  { value: "Goalkeeper", label: "Goalkeeper" },
];

export const userRoles = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  SUPPORT: "SUPPORT",
};

export const defaultPageSize = 10;
export const defaultPageNo = 1;
