import React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const SnackbarContext = React.createContext({});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackbarComponent() {
  const { snackBars, handleClose } = React.useContext(SnackbarContext);

  const _handleClose = (e, id) => {
    e?.stopPropagation();
    handleClose(id);
  }

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {snackBars.map((snackbar, index) => (
        <Snackbar
          key={snackbar.id} // Use unique ID to avoid duplicates
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={(e) => _handleClose(e, snackbar.id)}
          style={{ bottom: `${20 + index * 60}px` }} // Dynamic position
        >
          <Alert
            onClose={(e) => _handleClose(e, snackbar.id)}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      ))}
    </Stack>
  );
}

function SnackbarProvider({ children }) {
  const [snackBars, setSnackBars] = React.useState([]);

  const showSnackbar = (message="Success", status = "success") => {
    const id = new Date().getTime(); // Generate a unique ID
    setSnackBars((prev) => {
      // Check if the same message already exists
      if (prev.some((snackbar) => snackbar.message === message)) {
        return prev;
      }
      return [...prev, { id, message, severity: status, open: true }];
    });
  };

  const handleClose = (id) => {
    setSnackBars((prev) =>
      prev.map((snackbar) =>
        snackbar.id === id ? { ...snackbar, open: false } : snackbar
      )
    );

    setTimeout(() => {
      setSnackBars((prev) => prev.filter((snackbar) => snackbar.id !== id));
    }, 400);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, snackBars, handleClose }}>
      <SnackbarComponent />
      {children}
    </SnackbarContext.Provider>
  );
}

const useSnackbar = () => {
  const { showSnackbar } = React.useContext(SnackbarContext);

  return { showSnackbar };
};

export { SnackbarProvider, useSnackbar };
