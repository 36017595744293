export const getCurrenyTableColumns = (params) => {
  return [
    {
      id: "currencyId",
      label: `Currency ID`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "createdAt",
      label: `Created At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "updatedAt",
      label: `Updated At`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "isoCode",
      label: `ISO Code`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "isoNumericCode",
      label: `ISO Code`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "isoName",
      label: `ISO Name`,
      type: "string",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "minBet",
      label: `Min. Bet`,
      type: "number",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "maxBet",
      label: `Max. Bet`,
      type: "number",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "maxPayOut",
      label: `Max. Payout`,
      type: "number",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    {
      id: "gbpExchangeRate",
      label: `GBP EXCH Rate`,
      type: "number",
      isSortable: true,
      alignHead: "center",
      alignBody: "center",
    },
    { id: "actions", label: "Actions" },
  ];
};
