/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContentShell from "components/ContentShell";
import SearchInput from "components/SearchInput";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { opacity2hex, sortArray } from "utils/miscUtils";
import configData from "config/config.json";
import { useSnackbar } from "hooks/useSnackbar";
import usePaginationHook from "hooks/usePagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import sessionHelper from "services/sessionHelper";
import { PermittedRoles } from "utils/permissions";
import { getCurrenyTableColumns } from "utils/tableHelper/extraTableHelper";
import { getCurrencies } from "services/currencyService";
import CustomButton from "components/CustomButton";
import ExportButton from "components/ExportButton";
import { defaultPageNo, defaultPageSize } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: theme.palette.common.offWhite + opacity2hex("0.80"),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  container: {
    padding: "12px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const RowActions = ({ rowId, data }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowAction = (data) => {
    setAnchorEl(null);
    navigate(configData.routes.currency.edit?.replace(":id", data?._id));
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleRowAction(data)}>Edit</MenuItem>
        <MenuItem onClick={handleClose}>Delete</MenuItem>
      </Menu>
    </>
  );
};

const Currency = () => {
  const styles = useStyles();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [columns, setColumns] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const hasEditPermission = sessionHelper.hasRole(
    PermittedRoles.CURRENCY.UPDATE_ROLES
  );

  const { onChangePage, onPageSizeChange, pageNo, pageSize } =
    usePaginationHook({
      totalItems: filteredList?.length,
      defaultPageNo,
      defaultPageSize,
    });

  const createCurrency = () => navigate(configData.routes.currency.create);

  const fetchData = async (_search) => {
    setLoading(true);
    try {
      // feth leagues data
      const resp = await getCurrencies({
        pageNo,
        pageSize,
        searchValue: _search,
      });
      setFilteredList(resp?.data?.currencies);
      showSnackbar(resp?.message, "success");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbar(error.message);
    }
  };

  const onColumnClick = (column) => {
    let _list = sortArray([...filteredList], orderDirection, column);
    setFilteredList(_list);
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const onSearch = (value) => {
    setSearchValue(value);
    onPageSizeChange({ target: { value: defaultPageSize } });
    fetchData(value);
  };

  useEffect(() => {
    const cols = getCurrenyTableColumns();
    setColumns(cols.filter((col) => col.id !== "actions" || hasEditPermission));
    fetchData();
  }, [pageNo, pageSize]);

  return (
    <ContentShell
      title="Currencies"
      loading={loading}
      actions={
        <Box style={{ display: "flex", flexFlow: "row nowrap", gap: 4 }}>
          <SearchInput
            value={searchValue}
            placeholder="Search a currency"
            onSearch={onSearch}
          />
          <ExportButton list={filteredList} fileName="Currencies" />
          {sessionHelper.hasRole(PermittedRoles.CURRENCY.CREATE_ROLES) && (
            <CustomButton onClick={createCurrency}>Add</CustomButton>
          )}
        </Box>
      }
    >
      <Box className={styles.container}>
        <Table
          columns={columns}
          data={filteredList}
          currentPage={pageNo}
          pageSize={pageSize}
          totalEntries={filteredList.length}
          highlightAltRow={true}
          actions={RowActions}
          onColumnClick={onColumnClick}
          onChangePage={onChangePage}
          onRowsPerPageChange={onPageSizeChange}
        />
      </Box>
    </ContentShell>
  );
};

export default Currency;
