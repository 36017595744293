import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";

function ModalLayout({
  children,
  hasPadding = true,
  isLargeContainer = false,
  padding = 20
}) {
  const useStyles = makeStyles((theme) => {
    return {
      layoutContainer: {
        textAlign: "left",
        minWidth: 320,
        maxWidth: 810,
        maxHeight: 480,
        borderRadius: 20,
        padding: padding,
        position: "relative",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "@media (max-width: 900px)": {
          height: "100vh",
          minWidth: 280,
          minHeight: 620,
          maxHeight: 620,
          padding: 16,
        },
        [theme.breakpoints.down("xs")]: {
          padding: 14,
        },
      },
      increasedHeight: {
        height: 720,
      },
      removePadding: {
        padding: 0,
      },
    };
  });

  const classes = useStyles();

  return (
    <div
      className={classNames(classes.layoutContainer, {
        [classes.removePadding]: !hasPadding,
        [classes.increasedHeight]: isLargeContainer,
      })}
    >
      {children}
    </div>
  );
}

export default ModalLayout;
